<template>
  <div>
    <div style="width: 100vw; position: fixed; background-color: #ffff; height: 4.94505vw; z-index: 100; user-select: none">
      <div class="head">
        <div class="logo">
          <img class="logo_img" @click="navTo({ path: '/index' })" :src="require('../../assets/img/Logo_EN.svg')" alt="" />
        </div>

        <div class="el-menu" @click="hidderChat">
          <div class="el-menu-demo">
            <div class="el-submenu" @mouseover="submenuActive = 1" @mouseleave="submenuActive = 0" :class="submenuActive == 1 ? 'el-submenuActive' : ''">
              <span @click="navTo({ path: '/collections' })">{{ $t('menu.fenlei') }}</span>

              <!-- <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i> -->

              <div class="sub-menus">
                <div class="goods-classss">
                  <div :class="index == 0 ? 'clou2' : 'clou'" v-for="(item, index) of [...goodsClassList]" :key="index">
                    <div class="title no-p">{{ isChinese() ? item.name : item.nameEng }}</div>

                    <div class="item" v-for="(items, indexs) of item.sonList" :key="indexs" @click="navToproductSku(items)">{{ isChinese() ? items.name : items.nameEng }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="el-submenu">
              <span @click="navTo({ path: '/activity' })">{{ $t('menu.huodong') }}</span>
            </div>

            <div class="el-submenu" @mouseover="submenuActive = 3" @mouseleave="submenuActive = 0" :class="submenuActive == 3 ? 'el-submenuActive' : ''">
              <span>{{ $t('menu.fulanm') }}</span>

              <!-- <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i> -->

              <div class="sub-menus">
                <div class="serve">
                  <div class="text">
                    <div class="h3">{{ serveceColumn.title }}</div>

                    <span class="no-p">{{ serveceColumn.content }}</span>
                  </div>

                  <div class="pic c-p" @click="navTo({ path: '/commonProblem' })">
                    <img :src="serveceColumn.picture1" alt="" />

                    <div>{{ $t('footer.aftersale.question') }}</div>
                  </div>

                  <div class="pic c-p" @click="navTo({ path: '/vipSystem' })">
                    <img :src="serveceColumn.picture2" alt="" />

                    <div>{{ $t('menu.vipzhidu') }}</div>
                  </div>

                  <div class="afterSale">
                    <div style="margin-top: 1.37363vw" @click="navTo({ path: '/maintain' })">{{ $t('menu.weixiu[0]') }}</div>

                    <div @click="navTo({ path: '/inform' })">{{ $t('menu.weixiu[1]') }}</div>

                    <div @click="navTo({ path: '/charge' })">{{ $t('menu.weixiu[2]') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="el-submenu" @mouseover="submenuActive = 4" @mouseleave="submenuActive = 0" :class="submenuActive == 4 ? 'el-submenuActive' : ''">
              <span>{{ $t('menu.guanyuwomen') }}</span>

              <!-- <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i> -->

              <div class="sub-menus">
                <div class="serve">
                  <div class="text">
                    <div class="h3">{{ AboutUs.title }}</div>

                    <span class="no-p">{{ AboutUs.content }}</span>
                  </div>

                  <div class="pic c-p" @click="navTo({ path: '/About/introduction' })">
                    <img :src="AboutUs.picture1" alt="" />

                    <div>{{ $t('menu.about[0]').toUpperCase() }}</div>
                  </div>

                  <div class="pic c-p" @click="navTo({ path: '/recruitment' })">
                    <img :src="AboutUs.picture2" alt="" />

                    <div>{{ $t('menu.recruitment') }}</div>
                  </div>

                  <div class="pic c-p" @click="navTo({ path: '/blogs' })">
                    <img :src="AboutUs.picture3" alt="" />

                    <div>{{ $t('menu.boke') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="func">
          <div class="search">
            <el-autocomplete
              v-model="textKey"
              style="width: 10.98901vw"
              :fetch-suggestions="querySearchAsync"
              :placeholder="$t('menu.shareText')"
              @select="handleSelect"
              @keyup.enter.native="search"
            >
              <template slot-scope="{ item }">
                <div class="slotScope">
                  <span>{{ item.value }}</span>

                  <i @click.stop="sherchDelt(item)" class="el-icon-close"></i>
                </div>
              </template>
            </el-autocomplete>

            <img @click="search" class="searchimg" src="../../../public/imgs/head/search.png" alt="" />
          </div>

          <div class="funcs">
            <img @click="closeChat" src="../../assets/img/cart.svg" class="cartEntry" style="width: 1.26374vw; height: 1.26374vw; margin-right: 1.64835vw" />

            <el-dropdown @command="handleCommanduser">
              <span class="el-dropdown-link">
                <img src="../../assets/img/portrait.svg" alt="" style="width: 1.26374vw; height: 1.26374vw; margin-right: 1.64835vw" />
              </span>

              <el-dropdown-menu v-if="isLogin" slot="dropdown">
                <el-dropdown-item :command="index" v-for="(item, index) of menuList" :key="index"> {{ item }} </el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu v-else slot="dropdown">
                <el-dropdown-item :command="index" v-for="(item, index) of menuList" :key="index"> {{ item }} </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <div class="lang">
              <el-dropdown @command="handleCommand" trigger="click" :tabindex="1">
                <span class="el-dropdown-link" style="margin-right: 0.27473vw">
                  <img src="../../assets/img/lang_switch.svg" alt="" style="width: 1.26374vw; height: 1.26374vw" />

                  <span style="margin-left: 0.54945vw">{{ lang }}</span>

                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="index" v-for="(item, index) of langArr" :key="index">
                    <div style="display: flex; align-items: center">
                      <img style="width: 1.0989vw; height: 1.0989vw; margin-right: 0.54945vw" v-if="item.img" src="../../../public/imgs/head/active.png" alt=" " />

                      <img style="width: 1.0989vw; height: 1.0989vw; margin-right: 0.54945vw" v-else src="../../../public/imgs/head/on-pahc.png" alt="" />

                      <span>{{ item.title }}</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 4.94505vw"></div>
  </div>
</template>

<script>
import { listGoodsClassify, getServiceColumn, getAboutUs } from '@/api/main.js'
import $bus from '@/util/bus.js'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'topHead',
  inject: ['reload'],
  components: {},
  props: {
    closeChat: {
      //点击购物车打开
      type: Function,
      default() {
        return null
      }
    },
    hidderChat: {
      //点击任意页头购物车关闭
      type: Function,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      lang: '中文',
      langArr: [
        { title: 'English', img: false },
        { title: '中文', img: true }
      ],
      restaurants: [],
      textKey: '',
      timeout: null,
      goodsClassList: [], //分类
      serveceColumn: {}, //获取服务栏目信息
      AboutUs: {}, //获取关于我们嘞栏目信息
      submenuActive: -1,
      menuList: []
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    isLogin() {
      let token = localStorage.getItem('token')
      return !!token
    }
  },
  mounted() {
    const locale = localStorage.getItem('lang')
    if (locale) {
      this.handleCommand(locale == 'zh' ? 1 : 0)
    }
    this.restaurants = this.loadAll()
    this.getlistGoodsClassify()
    this.getServiceColumn() //获取服务栏目信息
    this.getAboutUs() //获取关于我们嘞栏目信息
    let token = localStorage.getItem('token')
    if (token) {
      this.menuList = this.$t('mine.menuList1')
    } else {
      console.log()
      const list = this.$t('mine.menuList')
      this.menuList = [list[list.length - 1]]
    }
  },

  methods: {
    ...mapMutations(['logOut']),
    getlistGoodsClassify() {
      //获取商品分类
      listGoodsClassify({}).then((res) => {
        if (res.code == 0) {
          this.goodsClassList = res.data
        } else {
          this.showMessage()
        }
      })
    },
    getServiceColumn() {
      //获取服务栏目信息
      getServiceColumn({}).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.serveceColumn = res.data
          } else {
            this.serveceColumn = {
              ...res.data,
              title: res.data.titleEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    },
    getAboutUs() {
      getAboutUs({}).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.AboutUs = res.data
          } else {
            this.AboutUs = {
              ...res.data,
              title: res.data.titleEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    },
    navToproductSku(items) {
      //根据商品分类去筛查商品
      this.submenuActive = 0
      // if (this.$route.path.indexOf('/productSku') >= 0) {
      //   $bus.$emit('productSku', { id: items.id })
      // } else {
      this.$router.push('/productSku?id=' + items.id)
      // }
    },
    toGoMint() {
      let toke = localStorage.getItem('token')

      this.submenuActive = 0
      if (toke) {
        this.$router.push({ path: '/mine/userInfo' })
      } else {
        this.$router.push({ path: '/login' })
      }
    },
    navTo(obj) {
      this.submenuActive = 0
      this.$router.push(obj.path)
    },
    handleCommanduser(e) {
      if (e == 6) {
        //退出登录/登录
        let token = localStorage.getItem('token')
        if (token) {
          this.logOut()
          this.$router.push('/login')
          this.reload()
        } else {
          this.$router.push('/mine/userInfo')
        }
        return
      }
      var path = {
        0: '/mine/userInfo',
        1: '/mine/favorite',
        2: '/mine/shoppingCart',
        3: '/mine/orderList',
        4: '/mine/vipGrade',
        5: '/mine/message',
        6: '/mine/setingPassword'
      }
      this.$router.push(path[e])
      if (this.$route.path.indexOf('/mine') >= 0) {
        $bus.$emit('updateRouter')
      }
      this.hidderChat() //点击我的列表购物车关闭一下
    },
    handleCommand(e) {
      this.langArr.forEach((item, index) => {
        const dom = document.getElementById('app')
        console.log(dom)
        // if (isShow) dom.classList.add('show_time_line')
        // else dom.classList.remove('show_time_line')

        if (index == e) {
          this.lang = item.title
          item.img = true
          this.$i18n.locale = 'zh'
          localStorage.setItem('lang', 'zh')
          dom.classList.add('zhcn')
        } else {
          item.img = false
          this.$i18n.locale = 'en'
          dom.classList.remove('zhcn')
          localStorage.setItem('lang', 'en')
        }
      })
    },
    sherchDelt(row) {
      debugger
      let searchList = JSON.parse(localStorage.getItem('searchList'))
      let arr = searchList.filter((item) => {
        if (item.value !== row.value) {
          return item
        }
      })
      localStorage.setItem('searchList', JSON.stringify(arr))
      this.reload()
    },
    loadAll() {
      let searchList = JSON.parse(localStorage.getItem('searchList'))
      return searchList ? searchList : []
    },
    querySearchAsync(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 10 * Math.random())
    },
    createStateFilter(queryString) {
      return (state) => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    search() {
      if (!this.textKey) return //没有搜索内容
      if (this.$route.path == '/search') {
        //如果是搜索页面直接更新搜索内容
        $bus.$emit('search', { textKey: this.textKey })
      } else {
        //如果不是带起内容跳转
        this.$router.push({ name: 'search', params: { textKey: this.textKey } })
      }
      //记录搜索内容去重等等
      let searchList = JSON.parse(localStorage.getItem('searchList'))
      if (searchList == null) {
        localStorage.setItem('searchList', JSON.stringify([{ value: this.textKey }]))
      } else {
        var isId = 0 //记录有没有重复向
        searchList.map((item) => {
          if (item.value == this.textKey) {
            isId += 1
          }
        })
        if (isId == 0) {
          //去重
          searchList.push({ value: this.textKey })
          localStorage.setItem('searchList', JSON.stringify(searchList))
        }
      }
      this.hidderChat() //点击搜索购物车关闭一下
    },
    handleSelect() {
      if (this.$route.path == '/search') {
        $bus.$emit('search', { textKey: this.textKey })
      } else {
        this.$router.push({ name: 'search', params: { textKey: this.textKey } })
      }
      this.restaurants = this.loadAll()
    }
  }
}
</script>

<style lang="scss">
.head {
  margin: 0 auto;
  width: 1700px;
  max-width: 1700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  // height: 90px;
  background-color: #ffff;
  position: sticky;
  top: 0px;
  z-index: 100;

  img {
    width: 248px;
    height: 88px;
  }

  .el-menu {
    width: 1130px;
    margin-left: 55px;
    border-right: none;

    .el-menu-demo {
      display: flex;
      //justify-content: space-around;
      align-items: flex-end;

      .el-submenu {
        //height: 124px;
        //text-align: center;
        font-size: 17px;
        // font-family: '微软雅黑';
        font-weight: 400;
        color: #909399;
        //width: 125px;
        padding-left: 30px;
        margin-right: 30px;
        overflow: hidden;
        vertical-align: bottom;
        position: relative;
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
        }
      }

      .el-icon-arrow-up {
        display: none;
      }

      // .el-submenu:hover {
      //     .el-icon-arrow-down {
      //         display: none;
      //         color: #909399;
      //     }

      //     .el-icon-arrow-up {
      //         display: inline;
      //     }

      //     .sub-menus {
      //         display: block;
      //         height: 350px;
      //         transition: all .3s ease .3s;
      //     }
      //     .menu-items{

      //         height: 152px;
      //         transition: all .3s ease .3s;
      //     }
      // }
      .el-submenuActive {
        .el-icon-arrow-down {
          display: none;
          color: #909399;
        }

        .el-icon-arrow-up {
          display: inline;
        }

        .sub-menus {
          display: block;
          height: 350px;
          transition: all 0.3s ease 0.3s;
        }

        .menu-items {
          height: 152px;
          transition: all 0.3s ease 0.3s;
        }
      }

      .sub-menus {
        position: fixed;
        top: 90px;
        left: 0vw;
        width: 100vw;
        height: 0px;
        background: #f0f0f0;
        transition: all 0.3s ease 0.3s;
        overflow: hidden;
        width: 100%;
      }

      .goods-classss {
        display: flex;
        //justify-content: center;
        margin: 0 auto;
        padding: 40px 30px 30px;
        cursor: pointer;
        width: 1700px;
        //width: 1200px;
        .clou {
          //  margin-left: 220px;
          margin: 0px 50px;
          //width: 220px;
          line-height: 25px;
          .title {
            font-size: 18px;
            // font-family: '微软雅黑';
            font-weight: 700;
            color: #000000;
            line-height: 25px;
            // letter-spacing: 0.3em;
            //margin-bottom: 32px;
          }

          .item {
            font-size: 16px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #333333;
            margin-top: 16px;
            line-height: 21px;
            // letter-spacing: 0.3em;
          }
        }
        .clou2 {
          //  margin-left: 220px;
          margin-right: 50px;
          //width: 220px;
          line-height: 25px;
          .title {
            font-size: 18px;
            // font-family: '微软雅黑';
            font-weight: 700;
            color: #000000;
            line-height: 25px;
            // letter-spacing: 0.3em;
            //margin-bottom: 32px;
          }

          .item {
            font-size: 16px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #333333;
            margin-top: 16px;
            line-height: 21px;
            // letter-spacing: 0.3em;
          }
        }
      }

      .serve {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 40px 30px 30px;
        height: 350px;
        width: 1700px;

        // padding: 0px 16%;
        .text {
          width: 440px;
          // letter-spacing: 0.05em;
          line-height: 24px;
          // height: 280px;
          //padding: 30px;
          padding-right: 30px;
          .h3 {
            font-size: 19px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #333333;
            font-size: 18px;
            margin-bottom: 20px;
            // letter-spacing: 0.3em;
          }

          span {
            font-size: 16px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #444;
            word-wrap: break-word;
            word-break: break-word;
            overflow: hidden;
          }

          //margin-right: 15px;
        }

        .pic {
          margin-right: 30px;

          img {
            width: 300px;
            height: 240px;
          }

          div {
            text-align: center;
            margin-top: 18px;
            font-size: 16px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #333333;
          }
        }

        .afterSale {
          border-left: 1px solid #333333;
          height: calc(100% - 140px);
          padding-left: 30px;
          div {
            text-align: left;
            margin-bottom: 18px;
            font-size: 16px;
            // font-family: '微软雅黑';
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
        }
      }

      .menu-items {
        position: fixed;
        top: 120px;
        width: 195px;
        height: 0px;
        background: #f2f2f2;
        overflow: hidden;
        transition: all 0.3s ease 0.3s;

        div {
          padding: 0px 10px;
          font-size: 15px;
          // font-family: '微软雅黑';
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-bottom: 20px;
          cursor: pointer;
          text-align: left;
        }
      }
    }
  }

  .func {
    display: flex;
    align-items: center;
    height: 28px;
    .funcs {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .lang {
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: keep-all;
        // width: 90px;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    .search {
      position: relative;
      margin-right: 30px;

      .searchimg {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
      }

      .el-input input {
        line-height: 34px;
        font-size: 12px;
      }
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #333333 !important;
  display: flex;
  align-items: center;
  img {
    vertical-align: middle;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
}

.slotScope {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    white-space: nowrap;
    /* 超出盒子部分隐藏 */
    overflow: hidden;
    /* 文本超出的部分打点显示 */
    text-overflow: ellipsis;
  }
}

.cartEntry {
  display: block;

  &:hover {
    cursor: pointer;
  }
}
.logo {
  // width: 176px;
  // height: 35px;
  // transform: scale(2) translate(-24px, -1px);
  display: flex;
  align-items: center;
  cursor: pointer;
  .logo_img {
    width: 80%;
    height: 80%;
  }
}
.no-p {
  cursor: default !important;
}
.c-p {
  cursor: pointer !important;
}
</style>
