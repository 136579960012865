<template>
  <div class="foots">
    <div class="serve">
      <div>
        <h3>{{ isChinese() ? '联系我们' : 'CONTACT US' }}</h3>
        <div class="div">{{ lianxi.name }}</div>
        <div class="div">{{ $t('footer.lianxi.tel') }}：{{ lianxi.tel }}</div>
        <div class="div">{{ $t('footer.lianxi.email') }}：{{ lianxi.eMail }}</div>
        <div class="div">{{ $t('footer.lianxi.time') }}：{{ lianxi.workTime }}</div>
        <div class="QRCode">
          <img :src="qrImg" alt="" />
          <div class="text">{{ isChinese() ? '官方微信公众号' : 'WeChat' }}</div>
          <!-- <img :src="this.code.code2" alt="" /> -->
        </div>
      </div>
      <div style="margin-left: 8.79121vw; margin-right: 2.74725vw">
        <h3>{{ $t('footer.about.title') }}</h3>
        <div class="div" @click="$router.push('/About/introduction')">{{ $t('footer.about.ppgs') }}</div>
        <div>
          <el-collapse>
            <el-collapse-item :title="$t('footer.about.gmqd')" name="1">
              <div class="div">
                <div class="scloor" @click.stop="navTo">
                  {{ isChinese() ? '天猫旗舰店' : 'Tmall' }}
                </div>
                <div class="scloor" @click.stop="navTo">
                  {{ isChinese() ? '京东旗舰店' : 'JD' }}
                </div>
                <div class="scloor" @click.stop="navTo">{{ isChinese() ? '亚马逊' : 'Amazon' }}</div>
                <div class="scloor" @click.stop="navTo">
                  {{ isChinese() ? '线下店铺' : 'Our Stores' }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="div" @click="$router.push('/About/ShoppingInstructions')">{{ isChinese() ? '购物须知' : 'Shopping Instructions' }}</div>
        <div class="div" @click="$router.push('/About/privacy')">{{ $t('footer.about.ystk') }}</div>
        <div class="div" @click="$router.push('/About/privacy')">{{ $t('footer.about.sytk') }}</div>
      </div>
      <div>
        <h3>{{ $t('footer.aftersale.title') }}</h3>
        <div class="div" @click="$router.push('/maintain')">{{ isChinese() ? '产品保养' : 'Product Care' }}</div>
        <div class="div" @click="$router.push('/inform')">{{ isChinese() ? '保修与维修' : 'Warranty & Repair' }}</div>
        <div class="div" @click="$router.push('/charge')">{{ isChinese() ? '退换货' : 'Returns & Refunds' }}</div>
        <div class="div" @click="$router.push('/commonProblem')">{{ $t('footer.aftersale.question') }}</div>
        <div class="div" @click="$router.push('/vipSystem')">{{ isChinese() ? '会员制度' : 'Membership System' }}</div>
        <div class="div" @click="navToA">{{ isChinese() ? '在线客服' : 'Contact Us' }}</div>
      </div>
    </div>
    <!-- <div class="email">
            <h3>{{$t('footer.join')}}</h3>
            <el-input style="width:19.23077vw;" :placeholder="$t('footer.emailAdrs')" v-model="email">
                <el-button slot="append" class="elInput" :loading="loading" @click="joinUs">{{$t('footer.dingyue')}}</el-button>
            </el-input>  
        </div> -->
    <div class="banQuan">
      <div v-if="isChinese()"><span class="span">2023 © 美地营家® 版权所有</span><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023001448号</a></div>
      <div v-else>Copyright © 2023 Yocamily®. All rights reserved.</div>
    </div>
  </div>
</template>

<script>
import { joinUs, getCompanyInfo, getORCodeUrl } from '@/api/main.js'
import qrImg from '@/assets/img/qrImg.jpg'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      qrImg,
      email: '',
      loading: false,
      lianxi: {},
      code: {}
    }
  },
  computed:{
    ...mapState(['userInfo'])
  },
  mounted() {
    this.getFooter()
  },
  methods: {
    getFooter() {
      getCompanyInfo({}).then((res) => {
        if (this.isChinese()) {
          this.lianxi = res.data
        } else {
          this.lianxi = {
            ...res.data,
            name: res.data.nameEng,
            workTime: res.data.workTimeEng
          }
        }
      })
      getORCodeUrl({ 1: 1 }).then((res) => {
        console.log(res)
        this.code = res.data
        //  if (this.isChinese()) {
        //         this.lianxi = res.data
        //     }else{
        //         this.lianxi = {
        //             ...res.data,
        //             name:res.data.nameEng,
        //             workTime:res.data.workTimeEng
        //         }
        //     }
      })
    },
    navTo() {
      this.$message(this.isChinese() ? '功能正在准备中,敬请期待' : 'Not yet enabled.')

      // window.open('https://www.baidu.com')
    },
    navToA() {
      // window.location.href = 'http://workerman.winderinfo.com/addons/kefu/index/index'
      // window.open('http://workerman.yocamily.com/addons/kefu/index/index')
      
      if(this.userInfo&&this.userInfo.id){
        const link=`http://workerman.yocamily.com/addons/kefu/kefu/index?user_id=${this.userInfo.id}&user_name=${this.userInfo.nickName||''}&email=${this.userInfo.eMail||''}&telphone=${this.userInfo.phoneNumber||''}`
        console.log(encodeURI(encodeURI(link)));
				window.open(encodeURI(encodeURI(link)))
      }else{
        window.open('http://workerman.yocamily.com/addons/kefu/index/index')
      }
    },
    joinUs() {
      var reg = new RegExp('^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$')
      if (!reg.test(this.email)) {
        if (this.isChinese()) {
          this.$message({
            message: '邮箱有误',
            type: 'warning'
          })
        } else {
          this.$message({
            message: 'Incorrect email address',
            type: 'warning'
          })
        }
        return
      }
      this.loading = true
      joinUs({
        email: this.email
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.$message({
              message: '加入成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: 'successfully added',
              type: 'success'
            })
          }
          this.loading = false
        } else {
          this.showMessage()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.el-collapse {
  border: 0;
}

.el-collapse-item__header {
  background-color: transparent;
  border: 0;
  height: 35px;
  line-height: 35px;
  color: #909399 !important;
  font-size: 18px;
}

.el-collapse-item__wrap {
  border: 0;
  background: transparent;
}

.el-collapse-item__content {
  padding: 0;
}
</style>

<style lang="scss" scoped>
.foots {
  background: #f9f9f9;
  padding: 95px 0;
  padding-bottom: 0;
}
// .foots,
.serve {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.serve {
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  h3 {
    font-size: 22px;
    //
    line-height: 25px;
    margin-bottom: 15px;
    color: #333333;
  }
  .div {
    font-size: 18px;
    //
    font-weight: 400;
    color: #909399;
    line-height: 35px;
    cursor: pointer;
    //padding-left: 6px;
    .scloor {
      padding: 0px 15px;
      line-height: 35px;
      color: #aaaaaa;
    }
  }
}
.email {
  h3 {
    font-size: 22px;
    //
    line-height: 25px;
    margin-bottom: 15px;
    color: #333333;
  }
  .elInput {
    width: 100px;
    height: 40px;
    background: #414042 !important;
    //border:0px 7px 7px 0px;
    color: #ffffff;
    border-radius: 0px;
    border: none;
  }
  .elInput:hover {
    background: rgb(36, 36, 36) !important;
    color: #000;
    background-color: transparent !important;
    opacity: 1;
    border: 1px solid #000;
    box-shadow: none;
  }
}
.QRCode {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  width: 180px;
  // height: 180px;
  margin-top: 20px;
  font-size: 0;
  img {
    display: block;
    // padding: 10px;
    // border: 1px solid #000;
    // margin-right: 20px;
    width: 180px;
    height: 180px;
  }
  .text {
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    color: #909399;
    overflow: visible;
    white-space: nowrap;
  }
}
.banQuan {
  text-align: center;
  line-height: 60px;
  font-size: 14px;
  color: #909399;
  user-select: none;
  background: #f8f8f8;
  .span {
    margin-right: 30px;
    /* em{
      position: relative;
      padding-right: 14px;
      font-style: normal;
      &::after{
        content: "®";
        font-size: 14px;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 14px;
        height: 14px;
        line-height: 14px;
      }
    } */
  }
  a {
  font-size: 14px;
    color: #909399;
  }
}
</style>
