<template>
  <div class="blogs pageWapper">
    <div class="title">{{ isChinese() ? '博客' : 'Blog'.toUpperCase() }}</div>
    <div class="cared">
      <div class="left" v-for="(item, index) of blogsList" :key="index" @click="navTo(item, index)">
        <div class="div">
          <img :src="item.cover" alt="" />
          <div class="view-div">
            <div class="time">{{ item.createTime.split(' ')[0] }}</div>
            <h3>{{ isChinese() ? item.title : item.titleEng }}</h3>
            <div class="intro">
              {{ isChinese() ? item.intro : item.introEng }}
            </div>
          </div>
        </div>
        <div class="tagA">{{ isChinese() ? '查看更多' : 'View More' }}</div>
      </div>
    </div>
    <div class="pagination">
      <div class="pages">
        <div class="dis">
          <!--  -->
          <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 1.64835vw"
            :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
          <div>{{ pageNum }} / {{ Math.ceil(total / 5) }}</div>
          <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 1.64835vw"
            :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listBlogs } from '@/api/main.js'
export default {
  name: 'blogs',
  data() {
    return {
      pagesIndex: 1,
      pageNum: 1,
      blogsList: [],
      total: 0
    }
  },
  activated() {
    console.log(this.$router)
  },
  beforeCreate() {
    console.log(456)
  },
  beforeRouteEnter(to, from, next) {
    console.log(from.name)
    next()
  },
  deactivated() { },
  mounted() {
    this.listBlogs()
  },
  methods: {
    listBlogs() {
      listBlogs({
        pageNum: this.pageNum,
        pageSize: 6
      }).then((res) => {
        if (res.code == 0) {
          this.blogsList.push(...res.data.rows)
          this.total = res.data.total
        } else {
          this.showMessage()
        }
      })
    },
    navTo(item) {
      this.$router.push('/details?id=' + item.id)
    },
    clickLeft() {
      if (this.pageNum <= 1) return
      this.pageNum--
      this.listBlogs()
    },
    clickRigth() {
      if (this.pageNum >= Math.ceil(this.total / 5)) return
      this.pageNum++
      this.listBlogs()
    }
  }
}
</script>
<style lang="scss">
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 90px;
}

.blogs {
  box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);

  .title {
    font-size: 32px;
    //
    font-weight: 700;
    color: #333333;
    padding: 40px 0px;
  }

  .cared {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    flex-wrap: wrap;

    .left {
      // margin-right: 28px;
      margin-bottom: 40px;

      .tagA {
        font-size: 14px;
        //
        font-weight: 400;
        color: #333333;
        text-decoration: underline;
        padding: 20px 0px;
        cursor: pointer;
      }

      .tagA:hover {
        color: #a1a1a1;
      }
    }

    .left:nth-child(4n) {
      margin-right: 0px;
    }

    .div {
      position: relative;

      img {
        width: 378px;
        height: 368px;
        object-fit: cover;
      }

      width: 378px;
      //height: 454px;
      // overflow: hidden;
    }

    .div:hover {
      .view-div {
        bottom: 0px;
      }
    }

    .view-div {
      width: 100%;
      background-color: #fff;
      padding: 20px 0px;
      transition: bottom 0.5s ease 0.1s;
      padding-bottom: 0px;

      .time {
        font-size: 14px;
        //
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 20px;
        //
      }

      .intro {
        color: #333333;
        padding: 16px 0px;
        padding-bottom: 0px;
        font-size: 16px;
        //
        font-weight: 400;
        line-height: 22px;
        height: 60px;
        overflow: hidden;
      }

      .buttom {
        width: 168px;
        padding: 8px 0px;
        background: #ffffff;
        border: 1px solid #333333;
        font-size: 14px;
        text-align: center;
        //
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }

      .buttom:hover {
        background: #000;
        color: #fff;
        transition: background 1s ease 0.1s, color 1s ease 0.1s;
      }
    }
  }

  .pagination {
    background-color: #ffffff;

    .pages {
      width: 1200px;
      padding: 40px 0px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;

      .dis {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        //
        font-weight: 400;
        color: #333333;
        width: 200px;
      }
    }
  }
}
</style>
