<template>
  <div class="content">
    <div class="tilte">
      <div></div>
      <span>{{ $t('menu.about[1]') }}</span>
    </div>
    <div class="introduce" v-html="obj.content"></div>
  </div>
</template>

<script>
import { getProtocol } from '@/api/main.js'
export default {
  data() {
    return {
      obj: {}
    }
  },
  mounted() {
    this.getProtocol()
  },
  methods: {
    getProtocol() {
      getProtocol({
        id: 2 //1品牌故事、2品牌介绍、3购物说明、4隐私权益及网站使用条款、5会员制度
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.obj = res.data
          } else {
            this.obj = {
              ...res.data,
              name: res.data.nameEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.content {
  width: 880px;
  // padding-top: 90px;
  .tilte {
    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      //
      font-weight: 400;
      color: #333333;
    }
  }
  .introduce {
    margin-top: 20px;
    width: 880px;
    border: 1px solid #dddddd;
    padding: 20px;
    font-size: 15px;
    //
    font-weight: 400;
    color: #333333;
    // letter-spacing: 2px;
    line-height: 25px;
  }
}
</style>
