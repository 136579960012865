<template>
    <div class="empty"> 
      <img src="../assets/img/empty.png" alt="">
      <div>{{ title }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'index',
    components: {},
    props: {
      title: {
        type: String,
        default: '为空'
      }
    },
    data() {
      return {
       
      }
    },
  
    mounted() {},
  
    methods: { }
  }
  </script>
  
  <style lang="scss">
  .empty{
    width: 300px;
    height: 200px;
    text-align: center;
    margin: auto;
    margin-top: 90px;
    div{
        text-align: center;
    }
    img{
        width: 300px;
         height: 150px;
    }
  }
  </style>