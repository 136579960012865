<template>
  <div>
    <!-- <div style="height: 2.8022vw;background-color: #fff;"></div> -->
    <div class="classes2">
      <div class="pageWapperC">
        <!-- <div v-if="videoUrl" @dblclick="onVideoDbClick">
          <video ref="video" :src="videoUrl" style="width: 100%; object-fit: cover" muted autoplay loop key="0x22">您的浏览器不支持 video 标签。</video>
        </div>
        <div v-else class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) of swiperList" :key="index">
              <img :src="item.picture" style="width: 100%" alt="" />
            </div>
          </div>
        </div> -->
        <div class="content__wrapper">
          <!-- <div style="height: 2.74725vw">&nbsp;</div> -->
          <div class="sort">
            <!-- @visible-change="e=> triggerClick" -->
            <el-dropdown trigger="click" @command="triggerClick">
              <span class="el-dropdown-link">
                {{ sort | returSort(isChinese()) }} <i class="el-icon--right" :class="!dropdownActive ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="null" icon="el-icon-star-off">{{ isChinese() ? '推荐' : 'Recommend' }}</el-dropdown-item>
                <el-dropdown-item :command="false" icon="el-icon-top">{{ isChinese() ? '升序' : 'Price Low to High' }}</el-dropdown-item>
                <el-dropdown-item :command="true" icon="el-icon-bottom">{{ isChinese() ? '降序' : 'Price High to Low' }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div style="display: flex; align-items: flex-start">
            <div class="strSku">
              <div style="font-size: 0.98901vw; line-height: 1.31868vw; font-weight: 540; margin-bottom: 2.30769vw">{{ isChinese() ? '全部商品' : 'ALL' }}：</div>

              <div role="tablist" aria-multiselectable="true" class="el-collapse" v-for="(item1, index) of classifications" :key="index">
                <div data-v-7bbf1321="" class="el-collapse-item is-active" style="font-size: 0.71429vw">
                  <div role="tab" aria-expanded="true" aria-controls="el-collapse-content-7967" aria-describedby="el-collapse-content-7967">
                    <div role="button" id="el-collapse-head-7967" tabindex="0" class="el-collapse-item__header is-active" style="font-size: 0.76923vw">
                      {{ isChinese() ? item1.name : item1.nameEng }}
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    aria-labelledby="el-collapse-head-7967"
                    id="el-collapse-content-7967"
                    class="el-collapse-item__wrap"
                    style="height: 2.52747vw; line-height: 2.52747vw"
                    v-for="(item2, index2) of item1.sonList"
                    :key="index2"
                  >
                    <div class="el-collapse-item__content">
                      <div>
                        <div class="classify_small" @click.stop="navToproductSku(item2)">{{ isChinese() ? item2.name : item2.nameEng }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <el-collapse v-model="activeNames" v-for="(item1, index) of classifications" :key="index">
                            <el-collapse-item :title="isChinese() ? item1.name : item1.nameEng" style="font-size: 1.0989vw;" :name="index">
                                <div v-for="(item2, index2) of item1.sonList" :key="index2">
                                    <div class="classify_small" @click.stop="navToproductSku(item2)">{{ isChinese() ? item2.name : item2.nameEng }}</div>
                                </div>
                            </el-collapse-item>
                        </el-collapse> -->
              <!-- <el-cascader-panel :options="classifications"></el-cascader-panel> -->
            </div>
            <div>
              <div class="list">
                <!-- initialStyle="productSku" -->
                <productList initialStyle="index" v-for="item of productList" :product="item" :key="item.id"></productList>
                <div v-for="i in 3 - (productList.length % 3)" :key="i" class="ttttt"></div>
              </div>
              <!-- <div class="pagination">
                            <div class="pages" v-if="total!=0">
                                <div class="dis">
                                    <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 1.64835vw;"
                                        :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
                                    <div>
                                        {{ pageNum }} / {{ Math.ceil(total / 10) }}
                                    </div>
                                    <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 1.64835vw;"
                                        :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
                                </div>
                            </div>
                        </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productList from '@/components/productList.vue'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import $bus from '@/util/bus.js'
import { listCarousel, listGoodsByFilterField, listGoodsClassify } from '@/api/main.js'
function isVideo(url) {
  return /(.)\.(mp4|rmvb|avi|ts)$/.test(url)
}
export default {
  components: { productList },
  data() {
    return {
      videoUrl: '',

      dropdownActive: false,
      pageNum: 1,
      swiperList: [],
      total: 0,
      goodsClassifyId: '', //分来id
      filterList: [],
      skuIndex: [],
      kety: [],
      productList: [],
      sort: null,
      mySwiper: null,
      classifications: [],
      activeNames: [0]
    }
  },
  mounted() {
    this.goodsClassifyId = null

    this.getlistCarousel() //获取轮播
    this.listGoodsByFilterField()
    this.getlistGoodsClassify()
    $bus.$on('productSku', this.updataFilterList)
  },
  filters: {
    returSort(val, isChinese) {
      if (val === null) {
        if (isChinese) {
          return '排序方式：推荐'
        } else {
          return 'Sort By：Recommend'
        }
      }
      if (isChinese) {
        return val ? '排序方式：降序' : '排序方式：升序'
      } else {
        return val ? 'Sort By: Price High to Low' : 'Sort By: Price Low to High'
      }
    }
  },
  methods: {
    onVideoDbClick() {
      this.$refs.video.requestFullscreen()
    },
    navToproductSku(items) {
      //根据商品分类去筛查商品
      this.submenuActive = 0
      if (this.$route.path.indexOf('/productSku') >= 0) {
        $bus.$emit('productSku', { id: items.id })
      } else {
        this.$router.push('/productSku?id=' + items.id)
      }
    },
    getlistGoodsClassify() {
      //获取商品分类
      listGoodsClassify({}).then((res) => {
        if (res.code == 0) {
          this.classifications = res.data
          var jilu = 0
          var newActiveNames = []
          this.classifications.forEach((itemel) => {
            newActiveNames.push(jilu)
            jilu = jilu + 1
          })
          this.activeNames = newActiveNames
        } else {
          this.showMessage()
        }
      })
    },
    updataFilterList(e) {
      this.pageNum = 1
      this.productList = []
      this.kety = []
      this.skuIndex = []
      this.filterList = []
    },
    listGoodsByFilterField() {
      //根据分类查询商品
      listGoodsByFilterField({
        key: null,
        pageNum: this.pageNum,
        pageSize: 100,
        sort: this.sort //   降序true，升序false
      }).then((res) => {
        if (res.code != 0) this.showMessage(res)
        this.total = res.data.total
        this.productList.push(...res.data.rows)
      })
    },
    getlistCarousel() {
      //test
      // this.swiperList = [
      //   {
      //     picture: 'https://www.yocamily.com/ds/profile/upload/2023/03/09/微信截图_20230308150355_20230309162541A004.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/banner1_20230325055927A003.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/联系我们2_20230325060008A005.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/banner2_20230325055939A004.png'
      //   }
      // ]
      // setTimeout(() => {
      //   this.initSwiper()
      // }, 400)
      // return

      //轮播
      listCarousel({ id: '' }).then((res) => {
        if (res.code != 0) this.showMessage(res)
        const resData = res.data || []

        const videoList = resData.filter((el) => {
          if (isVideo(el.picture)) return true
          return false
        })
        if (videoList.length) {
          this.videoUrl = videoList[0].picture
          return
        }
        this.swiperList = []
        if (resData.length) {
          for (let i = 0; i < resData.length; i++) {
            if (resData[i].picture.indexOf('.mp4') > -1) {
              this.swiperList = [resData[i]]
              return
            }
            this.swiperList.push(resData[i])
          }
          let time = setTimeout(() => {
            clearTimeout(time)
            setTimeout(() => {
              this.initSwiper()
            }, 400)
          }, 0)
        }
      })
    },
    //选择规格
    selecetSku(index, indexs, item) {
      this.skuIndex[index] = indexs
      this.kety[index] = item.id
      this.pageNum = 1
      this.productList = []
      this.listGoodsByFilterField()
    },
    triggerClick(e) {
      // this.dropdownActive=e
      this.sort = e
      listGoodsByFilterField
      console.log(e)
      this.pageNum = 1
      this.productList = []
      this.listGoodsByFilterField()
    },
    clickLeft() {
      //上一页
      if (this.pageNum <= 1) return
      this.pageNum--
      this.listGoodsByFilterField()
    },
    clickRigth() {
      //下一页
      if (this.pageNum >= Math.ceil(this.total / 10)) return
      this.pageNum++
      this.listGoodsByFilterField()
    },
    initSwiper() {
      this.mySwiper = new Swiper('.swiper-container', {
        direction: 'horizontal', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 3500, //时间间隔
          disableOnInteraction: false //*手动操作轮播图后不会暂停*
        },
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      })
      this.mySwiper.on('click', () => {
        if (this.swiperList[this.mySwiper.realIndex].link) {
          window.open(this.swiperList[this.mySwiper.realIndex].link)
        }
      })
    }
  },
  beforeDestroy() {
    $bus.$off('search')
  }
}
</script>

<style lang="scss" scoped>
.pageWapperC {
  // width: 1700px;
  // max-width: 1700px;
  // margin: 0 auto;
  // padding-top: 90px;
}
.classes2 {
  background: #f9f9f9;

  .el-dropdown-link {
    font-size: 14px;

    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }
  .sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 0px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    width: 1340px;
  }

  .strSku {
    width: 432px;
    // background: #fff;
    height: 100%;
    padding: 35px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-right: 30px;
    padding-top: 0px;
    padding-left: 0px;

    .el-collapse-item__header {
      font-size: 20px;
      color: #333333 !important;
    }
    .el-collapse-item:last-child {
      margin-bottom: 10px;
    }
    .classify_small {
      color: #333;
      margin-bottom: 5px;
      width: fit-content;
      text-align: center;
      margin-left: 6px;
      font-size: 20px;

      &:hover {
        cursor: pointer;
        border-bottom: solid 1px #909399;
      }
    }

    .arr {
      .checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        div {
          margin: 10px;
          width: 26px;
          height: 26px;
          border: 1px solid #cccccc;
          margin-right: 10px;
          font-size: 22px;

          font-weight: 400;
          color: #333333;
        }
        &:hover {
          color: #a1a1a1;
          transition: color 0.2s ease 0.2s;
        }
        .active {
          background: #2e2b2c;
        }
      }
      .text {
        font-size: 22px;

        font-weight: 400;
        color: #333333;
        padding: 10px 0px;
        &:hover {
          color: #a1a1a1;
          transition: color 0.2s ease 0.2s;
        }
      }
      .colors {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        img {
          width: 48px;
          height: 48px;
          background: #b8a9ce;
          margin-right: 10px;
          border: 2px solid #e2e2e2;
          cursor: pointer;
        }
        .active {
          border: 2px solid #000;
          transition: border 0.3s ease;
        }
      }
    }
  }
}
.pagination {
  background-color: #ffffff;
  .pages {
    width: 1200px;
    padding: 40px 0px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    .dis {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;

      font-weight: 400;
      color: #333333;
      width: 200px;
    }
  }
}

.el-dropdown-menu__item {
  padding: 5px 20px;
  line-height: 20px;
}
.content__wrapper {
  padding: 0 60px;
}
.swiper-slide {
  // width: 100vw !important;
  height: 1px !important;
}
.swiper-slide-active {
  height: auto !important;
}
</style>
