<template>
  <div>
    <div>
      <!-- controls="controls"  -->
      <div v-if="videoUrl" @dblclick="onVideoDbClick">
        <video ref="video" :src="videoUrl" style="width: 100%; object-fit: cover" muted autoplay loop>您的浏览器不支持 video
          标签。</video>
      </div>
      <div v-else class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) of swiperList" :key="index">
            <img :src="item.picture" style="width: 100%" alt="" />
          </div>
        </div>

        <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->
      </div>
      <div class="content__wrapper">
        <!-- <div style="height: 2.74725vw">&nbsp;</div> -->

        <div class="brand">
          <div>{{ obj.name }}</div>

          <div v-html="obj.content"></div>
        </div>

        <div class="box">
          <div class="div" v-for="(item, index) of skipClassifyList" :key="index"
            :style="{ marginRight: index === 0 ? '3.2967vw' : '' }">
            <!--:style="{bottom:skuipClassifyInde==index ? heights:''}"@mouseover="submenuActive = 1" @mouseleave="submenuActive = 0" :class="skuipClassifyInde==index?'activeview-div':''" -->

            <img :src="item.cover" alt="" mode="widthFix" />

            <div class="view-div view-divs">
              <h3>{{ isChinese() ? item.title : item.titleEng }}</h3>

              <div class="intro">{{ isChinese() ? item.content : item.contentEng }}</div>

              <div class="buttom" @click="$router.push('/productSku?id=' + item.goodsClassifyId)">{{ $t('button.chakan')
                }}</div>
            </div>
          </div>
        </div>

        <div class="product-List">
          <h2>{{ isChinese() ? '推荐商品' : 'RECOMMENDED FOR YOU' }}</h2>

          <div class="list">
            <productListX initialStyle="index" v-for="item of productList" :product="item" :key="item.id">
            </productListX>
          </div>
        </div>

        <div class="contactUS">
          <h2>{{ isChinese() ? '联系我们' : 'CONTACT US' }}</h2>

          <div class="box">
            <div v-for="(item, index) of contaList" :key="index"
              :style="{ marginRight: index === 0 ? '3.2967vw' : '', width: '100%' }"
              @click="$router.push({ path: 'contactUs', query: { id: item.id } })">
              <div class="div">
                <img :src="item.cover" alt="" />

                <div class="view-div">
                  <h3>{{ isChinese() ? item.title : item.titleEng }}</h3>

                  <div class="intro">{{ isChinese() ? item.intro : item.introEng }}</div>
                </div>
              </div>

              <div class="tagA">{{ $t('button.gengduo') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="JoinUS">
            <h2>{{ $t('footer.jiaru') }}</h2>
            <h3>{{ $t('footer.title') }}</h3>
            <el-input style="width:19.23077vw;" :placeholder="$t('footer.emailAdrs')" v-model="email">
                <el-button slot="append" class="elInput" :loading="loading" @click="joinUs">{{ $t('footer.dingyue')
                }}</el-button>
            </el-input>
        </div> -->
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import productListX from '@/components/productList2.vue'
import { listCarousel, listGoods, listSkipClassify, listContactUs, getProtocol, joinUs } from '@/api/main.js'
function isVideo(url) {
  return /(.)\.(mp4|rmvb|avi|ts)$/.test(url)
}
export default {
  components: { productListX },
  data() {
    return {
      videoUrl: '',
      input2: '',
      swiperList: [],
      productList: [],
      skipClassifyList: [], //获取跳转分类
      contaList: [], //获取联系我们
      obj: {},
      email: '',
      loading: false,
      heights: ''
    }
  },
  mounted() {
    this.getlistCarousel() //获取轮播
    this.getlistGoods() //获取商品
    this.listSkipClassify() //获取跳转分类
    this.listContactUs() //获取联系我们

    this.getProtocol() //2品牌介绍
  },
  methods: {
    onVideoDbClick() {
      this.$refs.video.requestFullscreen()
    },
    initH() {
      console.log(this.$ref['boxs1'])
    },
    getProtocol() {
      getProtocol({
        id: 2 //1品牌故事、2品牌介绍、3购物说明、4隐私权益及网站使用条款、5会员制度
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.obj = res.data
          } else {
            this.obj = {
              ...res.data,
              name: res.data.nameEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    },
    listContactUs() {
      //获取联系我们
      listContactUs({}).then((res) => {
        if (res.code == 0) {
          this.contaList = res.data
        } else {
          this.showMessage()
        }
      })
    },
    listSkipClassify() {
      //获取跳转分类
      listSkipClassify({}).then((res) => {
        if (res.code == 0) {
          this.skipClassifyList = res.data
        } else {
          this.showMessage()
        }
      })
    },
    getlistCarousel() {
      //test
      // this.swiperList = [
      //   {
      //     picture: 'https://www.yocamily.com/ds/profile/upload/2023/03/09/微信截图_20230308150355_20230309162541A004.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/banner1_20230325055927A003.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/联系我们2_20230325060008A005.png'
      //   },
      //   {
      //     picture: 'http://www.yocamily.com/ds/profile/upload/2023/03/25/banner2_20230325055939A004.png'
      //   }
      // ]
      //  setTimeout(() => {
      //         this.initSwiper()
      //       }, 400)
      // return

      try {
        listCarousel({ id: '' }).then((res) => {
          if (res.code != 0) this.showMessage(res)
          const resData = res.data || []
          const videoList = resData.filter((el) => {
            if (isVideo(el.picture)) return true
            return false
          })
          if (videoList.length) {
            this.videoUrl = videoList[0].picture
            return
          }

          this.swiperList = []
          if (resData.length) {
            for (let i = 0; i < resData.length; i++) {
              if (resData[i].picture.indexOf('.mp4') > -1) {
                this.swiperList = [resData[i]]
                return
              }
              this.swiperList.push(resData[i])
            }
            let time = setTimeout(() => {
              clearTimeout(time)
              setTimeout(() => {
                this.initSwiper()
              }, 400)
            }, 0)
          }
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    getlistGoods() {
      listGoods({
        tuijianStatus: 1,
        // name:'直',
        pageNum: 1,
        pageSize: 4
      }).then((res) => {
        if (res.code == 0) {
          this.productList = res.data.rows
        } else {
          this.showMessage()
        }
      })
    },
    joinUs() {
      var reg = new RegExp('^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$')
      if (!reg.test(this.email)) {
        if (this.isChinese()) {
          this.$message({
            message: '邮箱有误',
            type: 'warning'
          })
        } else {
          this.$message({
            message: 'Incorrect email address',
            type: 'warning'
          })
        }
        return
      }
      joinUs({
        email: this.email
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.$message({
              message: '加入成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: 'successfully added',
              type: 'success'
            })
          }
        } else {
          this.showMessage()
        }
      })
    },
    initSwiper() {
      let swiper = new Swiper('.swiper-container', {
        direction: 'horizontal', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 3500, //时间间隔
          disableOnInteraction: true //*手动操作轮播图后不会暂停*
        },
        loop: true, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 分页器可以点击
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      })
      swiper.on('click', () => {
        const jumpUrl = this.swiperList[swiper.realIndex].link
        console.log(jumpUrl);
        if (jumpUrl) {
          // window.location.origin
          if (jumpUrl.indexOf('www.yocamily.com') >= 0) {
            window.location.href = jumpUrl
          } else {
            window.open(jumpUrl)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pageWapper {
  // width: 1700px;
  // max-width: 1700px;
  // margin: 0 auto;
  // padding-top: 90px;
}

.swiper-pagination {
  position: absolute;
  bottom: -0.1rem;
  right: 1rem;
  text-align: right !important;
}

.swiper-container {
  // min-height: 50vh;
}

.swiper-container ::v-deep .swiper-pagination-bullet-active {
  background-color: #fff;
}

.brand {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 100px 60px 100px;

  div:last-child {
    width: 1060px;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }

  div:first-child {
    width: 220px;
    font-size: 36px;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
  }
}

.box {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  background-color: #fff;

  .tagA {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    text-decoration: underline;
    padding: 20px 50px;
    cursor: pointer;
  }

  .tagA:hover {
    color: #a1a1a1;
  }

  .divMage {
    margin-right: 90px;
    margin-left: 90px;
  }

  .div {
    position: relative;

    img {
      // width: 720px;
      width: 100%;
      //   height: 100%;
      // height: 660px;
    }

    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .div:hover {
    .view-div {
      bottom: 0px;
      transition: all 0.5s ease 0.1s;
    }
  }

  .view-div {
    width: 100%;
    background-color: #fff;
    padding: 30px 50px;
    position: absolute;
    bottom: -78px;
    transition: all 0.5s ease 0.1s;
    padding-bottom: 0px;

    //max-height: 220px;
    h3 {
      font-size: 24px;
    }

    .intro {
      font-weight: 400;
      color: #333333;
      padding: 30px 0px;
      word-wrap: break-word;
      word-break: break-word;
      height: 136px;
      line-height: 28px;
    }

    .buttom {
      width: 168px;
      padding: 8px 0px;
      background: #ffffff;
      border: 1px solid #333333;
      font-size: 18px;
      text-align: center;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }

    .buttom:hover {
      background: #000;
      color: #fff;
      transition: background 1s ease 0.1s, color 1s ease 0.1s;
    }
  }

  .view-divs {
    bottom: -180px;
    padding: 30px 50px;
  }
}

.product-List {
  background: #ffffff;

  h2 {
    padding: 105px 0px;
    text-align: center;
    font-size: 28px;
    color: #333333;
    line-height: 40px;
  }

  .list {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 1700px;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.contactUS {
  padding-bottom: 60px;

  h2 {
    padding: 125px 0px;
    text-align: center;
    font-size: 28px;

    color: #333333;
    line-height: 40px;
  }
}

.JoinUS {
  text-align: center;
  background: #f9f9f9;
  padding: 98px 0px;

  h3 {
    padding: 32px;
  }

  .elInput {
    width: 130px;
    height: 40px;
    background: #414042 !important;
    color: #fff;
    border-radius: 0px;
    border: none;
  }

  .elInput:hover {
    background: rgb(36, 36, 36) !important;
    color: #000;
    background-color: transparent !important;
    opacity: 1;
    border: 1px solid #000;
    box-shadow: none;
  }
}

.content__wrapper {
  padding: 0 60px;
}

.swiper-slide {
  // width: 100vw !important;
  height: 1px !important;
}

.swiper-slide-active {
  height: auto !important;
}
</style>
