<template>
  <div class="goods">
    <div class="tilte" @click="$router.back()">
      <div></div>
      <span>{{ getStatus(orderDetails.status) }}</span>
    </div>
    <div class="product">
      <div class="list" v-for="(items, index) of orderDetails.detailList" :key="index">
        <img class="pic" :src="items.cover" alt="" />
        <div class="productDatil">
          <div class="name dis">
            <span>{{ isChinese() ? items.name : items.nameEng }}</span>
            <span>¥{{ Number(items.price).toFixed(2) }}</span>
          </div>
          <div class="sku">
            <div><span></span> {{ isChinese() ? items.keyName : items.keyNameEng }}</div>
            <span>x{{ items.number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="address">
      <div class="title">{{ $t('mine.pepol.adr') }}</div>
      <div class="str">{{ orderDetails.address }}</div>
    </div>
    <div class="orderDetails">
      <div>
        <span>{{ $t('mine.elses.dingdanbiaohao') }}</span>
        <div>{{ orderDetails.orderNumber }}</div>
      </div>
      <div>
        <span>{{ $t('mine.elses.xiadantime') }}</span>
        <div>{{ orderDetails.createTime }}</div>
      </div>
      <div v-if="orderDetails.status >= 0">
        <span>{{ $t('mine.elses.zhifutype') }}</span>
        <div>{{ orderDetails.status | getStauts(isChinese()) }}</div>
      </div>
      <div v-if="orderDetails.payTime">
        <span>{{ $t('mine.elses.payTime') }}</span>
        <div>{{ orderDetails.payTime }}</div>
      </div>
      <div v-if="orderDetails.totalPrice">
        <span>{{ $t('mine.elses.payjine') }}</span>
        <div>￥{{ Number(orderDetails.totalPrice).toFixed(2) }}</div>
      </div>
      <div v-if="orderDetails.deliverTime">
        <span>{{ $t('mine.elses.fahuotime') }}</span>
        <div>{{ orderDetails.deliverTime }}</div>
      </div>
      <div v-if="orderDetails.receiveTime">
        <span>{{ $t('mine.elses.shouhuotime') }}</span>
        <div>{{ orderDetails.receiveTime }}</div>
      </div>
      <div v-if="orderDetails.status == 5">
        <!-- //取消订单时间 -->
        <span>{{ $t('mine.elses.quxiaotime') }}</span>
        <div>{{ orderDetails.cancelTime }}</div>
      </div>

      <div v-if="orderDetails.logisticsName">
        <!-- //取消订单时间 -->
        <span>{{ isChinese() ? '物流公司' : 'Courier' }}</span>
        <div>{{ orderDetails.logisticsName }}</div>
      </div>
      <div v-if="orderDetails.logisticsNo">
        <!-- //取消订单时间 -->
        <span>{{ isChinese() ? '物流单号' : 'Tracking Number' }}</span>
        <div>{{ orderDetails.logisticsNo }}</div>
      </div>
      <template v-if="trackList.length>0">

        <div>
          <span>{{ $t('button.wuliuxiangqing') }}</span>
        </div>
        <div v-for="(item,index) in trackList" :key="index" style="justify-content: space-between;">
          <span>{{ item.context }}</span>
          <div class="nowarp">{{ item.time }}</div>
        </div>
      </template>
    </div>
    
    <div class="after">
      <div class="time">
        <!-- <template v-if="orderDetails.status == 1">
          <span style="color: #e92d2d; font-weight: 700">{{ $t('mine.elses.payjine') }}：</span>
          <span style="color: #e92d2d; font-weight: 700">￥{{ orderDetails.totalPrice }}</span>
        </template> -->

        <span v-if="orderDetails.status == 2">{{ $t('mine.elses.zdshouhuotime') }}：</span>
        <span v-if="orderDetails.status == 2">{{ count }}</span>
        <span v-if="orderDetails.status == 0" style="color: #333333; font-weight: 700">{{ $t('mine.orderList[0]') }}：</span>
        <span v-if="orderDetails.status == 0" style="color: #e92d2d; font-weight: 700">￥{{ Number(orderDetails.totalPrice).toFixed(2) }}</span>
      </div>
      <div style="display: flex; justify-content: flex-end; align-items: center">
        <div class="button" v-if="orderDetails.status == 0" @click="showDialog(1)">{{ $t('button.quxiaodingdan') }}</div>
        <!--取消订单-->
        <div class="button blck" v-if="orderDetails.status == 0" @click="payOrderWeb">{{ $t('button.quzhifu') }}</div>
        <!--去支付-->
        <div class="button" v-if="orderDetails.status == 1" @click="showDialog(2)">{{ $t('button.tuikuan') }}</div>
        <!--申请退款-->
        <div class="button blck" v-if="orderDetails.status == 2" @click="showDialog(3)">{{ $t('button.shouhuo') }}</div>
        <!--确认收货-->
        <div class="button" @click="$router.push('/mine/afterSale?id=' + orderId)" v-if="orderDetails.status == 3 || orderDetails.status == 4">
          {{ $t('button.shenqingshouhuo') }}
        </div>
        <!-- 申请售后 -->
        <div class="button blck" @click="$router.push('/mine/orderScore?id=' + orderId)" v-if="orderDetails.status == 3">{{ $t('button.qupingjia') }}</div>
        <!--去评价-->
        <div class="button" v-if="orderDetails.status == 6" @click="showDialog(4)">{{ $t('button.chexiao') }}</div>
        <!--撤销申请-->
        <div class="button blck" @click="$router.push('/mine/lookApply?id=' + orderId)" v-if="orderDetails.status == 6">{{ $t('button.see') }}</div>
        <!--查看申请-->
      </div>
    </div>
    <el-dialog
      :title="dialogType == 1 ? $t('message.quxiaotisi') : dialogType == 2 ? $t('mine.elses.tuikuanTime') : dialogType == 3 ? $t('mine.elses.querentis') : $t('button.chexiao')"
      :visible.sync="centerDialogVisible"
      width="20%"
      center
      top="15%"
    >
      <div class="dialog">
        {{
          dialogType == 1
            ? $t('mine.elses.quxiaodingdan')
            : dialogType == 2
            ? $t('mine.elses.tuikuanContent')
            : dialogType == 3
            ? $t('mine.elses.querenShouhuo')
            : $t('mine.elses.chexiaoshenqing')
        }}
      </div>
      <span slot="footer" class="dialog-footer">
        <div @click="cancel" style="border-right: undefined solid #ececec">{{ dialogType == 2 ? $t('mine.elses.button') : $t('button.quxiao') }}</div>
        <div @click="confirm">{{ $t('button.queding') }}</div>
      </span>
    </el-dialog>
    <el-dialog title=" " :visible.sync="payDialogVisible" width="30%" :before-close="handleClose">
      <div class="pay">
        <div class="title">{{ $t('submitOrder.yingfujine') }}</div>
        <div class="total">¥{{ Number(orderDetails.totalPrice).toFixed(2) }}</div>
        <div class="type">
          <div class="wx">
            <div>
              <img :src="wchatPayPic" alt="" />
            </div>
            <div>
              <img src="../../../public/imgs/mine/wxpay.png" alt="" />
              {{ $t('submitOrder.wahctQce') }}
            </div>
          </div>
        </div>
        <el-button type="primary" style="padding: 0.82418vw 1.0989vw; margin: auto" @click="getOrderStatus">{{ isChinese() ? '支付成功' : 'Payment Success' }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="isChinese() ? '选择支付方式' : 'Select Payment Method'" :visible.sync="dialogVisible" width="27.47253vw" :before-close="handleClose" center>
      <div class="fabumainboxpay">
        <div class="fabumainboxpay-titlef">
          <p class="fabumainboxpay-p">￥{{ Number(orderDetails.totalPrice).toFixed(2) }}</p>
        </div>
        <div class="fabumainboxpay-pay">
          <div class="fabumainboxpay-pay-line" @click="selcetPayType(item, index)" v-for="(item, index) of payTypeList" :key="index">
            <div class="flexbox">
              <img :src="item.img" alt="" class="wximg" />
              <div>{{ isChinese() ? item.text : item.textEn }}</div>
            </div>
            <img :src="item.ico" class="chooseimg" />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer-ggpay">
        <el-button @click="cancel">{{ $t('button.quxiao') }}</el-button>
        <el-button type="primary" @click.stop="topay">{{ $t('button.queding') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getOrderDetails, cancelOrder, receiveOrder, revocationOrder, payOrderWeb, getOrderStatus,getTrack } from '@/api/main.js'
import base from '@/config/env'
export default {
  data() {
    return {
      centerDialogVisible: false,
      indexStatus: 0, //0待支付，1待发货，2待收货，3待评价，4已完成，5已取消，6退款/售后申请，7审核成功，8审核失败，9已退款。默认0
      orderId: '',
      orderDetails: {},
      dialogType: 1, //1取消订单  2.申请退款 3.收货提示 4.撤销退款申请
      count: '', //倒计时
      seconds: 0, // 10天的秒数

      wchatPayPic: '',
      alipayPic: '',
      payDialogVisible: false,

      dialogVisible: false,
      selectPayRoder: {}, //选中的支付订单
      payTypeList: [
        { text: '微信支付', textEn: 'WeChat Payment', img: require('../../../public/imgs/mine/wxpay.png'), ico: require('../../../public/imgs/yes.png'), type: 1 },
        { text: '支付宝支付', textEn: 'Alipay Payment', img: require('../../../public/imgs/mine/alipay.png'), ico: require('../../../public/imgs/ofOn.png'), type: 2 }
      ],
      payType: 1,
      trackList:[]
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getOrderDetails()
  },
  filters: {
    getStauts(status, isChinese) {
      if (isChinese) {
        return status == 0 ? '待支付' : status == 9 ? '已退款' : status == 5 ? '待支付' : '已支付'
      } else {
        return status == 0 ? 'Unpaid' : status == 9 ? 'Refunded' : status == 5 ? 'Unpaid' : 'Have Paid'
      }
    }
  },
  computed: {},
  methods: {
    selcetPayType(item, index) {
      this.payTypeList.forEach((e, i) => {
        if (i == index) {
          e.ico = require('../../../public/imgs/yes.png')
        } else {
          e.ico = require('../../../public/imgs/ofOn.png')
        }
      })
      this.payType = item.type
    },
    cancel() {
      this.centerDialogVisible = false
      this.dialogVisible = false
    },
    confirm() {
      if (this.dialogType == 1) {
        //取消订单
        cancelOrder({
          orderId: this.orderId
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: this.isChinese() ? '取消成功' : 'Cancel successfully',
              type: 'success'
            })
            this.centerDialogVisible = false
            this.getOrderDetails()
          } else {
            this.showMessage(res)
          }
        })
      } else if (this.dialogType == 2) {
        //申请退款
        this.centerDialogVisible = false
        this.$router.push('/mine/afterSale?id=' + this.orderId)

        // refundAfterOrder({
        //   orderId:this.orderId
        // }).then(res=>{
        //   if(res.code==0){
        //     if(this.isChinese()){
        //       this.$message({
        //           message: '提交成功',
        //           type: 'success'
        //       });
        //     }else{
        //       this.$message({
        //           message: 'Submit successfully',
        //           type: 'success'
        //       });
        //     }
        //     this.centerDialogVisible = false
        //     this.getOrderDetails();
        //   }else{
        //     this.showMessage(res)
        //   }
        // })
      } else if (this.dialogType == 3) {
        //确认收货
        receiveOrder({
          orderId: this.orderId
        }).then((res) => {
          if (res.code == 0) {
            if (this.isChinese()) {
              this.$message({
                message: '收货成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: 'Goods received successfully',
                type: 'success'
              })
            }
            this.centerDialogVisible = false
            this.getOrderDetails()
          } else {
            this.showMessage(res)
          }
        })
      } else {
        //撤销申请
        revocationOrder({
          orderId: this.orderId
        }).then((res) => {
          if (res.code == 0) {
            if (this.isChinese()) {
              this.$message({
                message: '撤销成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: 'Undo successfully',
                type: 'success'
              })
            }
            this.centerDialogVisible = false
            this.getOrderDetails()
          } else {
            this.showMessage(res)
          }
        })
      }
    },
    showDialog(type) {
      this.dialogType = type
      this.centerDialogVisible = true
    },
    getStatus(status) {
      if (status == 9) {
        return this.$t('mine.orderList')[6]
      } else {
        return this.$t('mine.orderList')[status]
      }
    },
    getOrderDetails() {
      console.log('userinfo:', this.$store.state.userInfo)
      getOrderDetails({
        userId: this.$store.state.userInfo.id,
        orderId: this.orderId
      }).then((res) => {
        if (res.code == 0) {
          if (!res.data) {
            this.$router.replace('/mine/orderList')
          } else {
            this.orderDetails = res.data
            if (res.data.status == 2) {
              this.Time(res.data.receiveDeadline)
              this.getTrack()
            }
          }
        } else {
          this.showMessage(res)
        }
      })
    },
    getTrack(){
      getTrack({  
        id: this.orderId
      }).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          if(res.data.status=='200'){
            this.trackList=res.data.data
          }
        } else {
          this.showMessage(res)
        }
      })
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60))
      d = d < 10 ? '0' + d : d
      let h = parseInt((this.seconds / (60 * 60)) % 24)
      h = h < 10 ? '0' + h : h
      let m = parseInt((this.seconds / 60) % 60)
      m = m < 10 ? '0' + m : m
      let s = parseInt(this.seconds % 60)
      s = s < 10 ? '0' + s : s
      if (this.isChinese()) {
        this.count = d + '天' + h + '时' + m + '分' + s + '秒'
      } else {
        this.count = d + 'day' + h + 'hour' + m + 'minute' + s + 'second'
      }
    },
    //定时器没过1秒参数减1
    Time(time) {
      var timeStr=time
      if(time.indexOf('-')>=0){
        timeStr=timeStr.replace(/-/g, "/")
      }
      //获取当前时间
      var date = new Date()
      var now = date.getTime()
      //设置截止时间
      var endDate = new Date(timeStr)
      var end = endDate.getTime()
      if (end - now >= 0) {
        this.seconds = (end - now) / 1000
        setInterval(() => {
          this.seconds -= 1
          this.countDown()
        }, 1000)
      } else {
        this.seconds = 0
        this.countDown()
      }
    },
    //验证支付成功未成功按钮
    getOrderStatus() {
      getOrderStatus({
        orderId: this.orderId
      }).then((res) => {
        if (res.code == 0) {
          if (res.data == 0) {
            this.$message({
              type: 'warning',
              message: this.isChinese() ? '支付未成功' : 'Payment failed'
            })
          } else {
            this.payDialogVisible = false
            this.$message({
              type: 'success',
              message: this.isChinese() ? '支付成功' : 'Payment success'
            })
            this.getOrderDetails()
          }
        }
      })
    },
    topay() {
      //支付方式
      payOrderWeb({
        orderId: this.orderId,
        type: this.payType //1微信 2.支付宝
      }).then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false
          if (this.payType == 1) {
            this.payDialogVisible = true
            this.wchatPayPic = 'https://www.yocamily.com/ds/api/getQRCode?code_url=' + res.orderString
          } else {
            document.write(res.orderString)
          }
        } else {
          this.showMessage(res)
        }
      })
    },
    payOrderWeb() {
      //支付接口
      this.dialogVisible = true
    },
    handleClose() {
      this.payDialogVisible = false
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
.goods {
  width: 880px;
  padding-top: 90px;
  .tilte {
    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }

  .product {
    background: #f9f9f9;
    padding: 16px;
    margin: 20px 0px;
    .list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .pic {
        width: 120px;
        height: 120px;
        background: #ece8f1;
        object-fit: cover;
      }

      .productDatil {
        width: 700px;

        .dis {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .name {
          span {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
          }

          i {
            font-size: 25px;
          }
        }

        .sku {
          padding: 22px 0px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }

        .del,
        .sku {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;

            font-weight: 400;
            color: #aaaaaa;
          }
        }

        .del {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .input-number {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 90px;
          height: 30px;
          background: #f2f2f2;
          padding: 0px 5px;

          div {
            font-size: 16px;

            font-weight: 400;
            color: #000000;
            line-height: 22px;
          }

          .font {
            font-size: 19px;
            color: #000000;
          }
        }
      }
    }
  }
  .address {
    padding: 30px 0px;
    border-bottom: 1px solid #eeeeee;
    .title {
      font-size: 18px;

      font-weight: 400;
      color: #333333;
      line-height: 25px;
    }
    .str {
      font-size: 16px;

      font-weight: 400;
      color: #333333;
      margin-top: 16px;
    }
  }
  .orderDetails {
    padding: 30px 0px;
    > div {
      display: flex;
      align-items: center;
      padding: 6px 0px;
      span {
        font-size: 15px;

        font-weight: 400;
        color: #666666;
      }
      div {
        font-size: 15px;

        font-weight: 400;
        color: #333333;
        margin-left: 20px;
      }
      .nowarp{
        white-space: nowrap;
      }
    }
  }
  .after {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #f9f9f9;
    margin-top: 20px;
    font-size: 16px;

    font-weight: 400;
    color: #333333;
    .time {
      font-size: 14px;

      font-weight: 400;
      color: #333333;
    }
    .button {
      padding: 7px 15px;
      text-align: center;
      border: 1px solid #cccccc;
      font-size: 16px;

      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-left: 16px;
      cursor: pointer;
    }
    .blck {
      background: #000000;
      color: #ffffff;
    }
  }
}
.dialog {
  text-align: center;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ececec;
  div {
    width: 224px;
    padding: 24px 0px;
    padding-bottom: 4px;
    cursor: pointer;
  }
}
.pay {
  text-align: center;
  .title {
    font-size: 16px;

    font-weight: 400;
    color: #777777;
    line-height: 22px;
    padding: 15px 0px;
    text-align: center;
  }

  .total {
    font-size: 36px;

    font-weight: 400;
    color: #333333;
    line-height: 50px;
    text-align: center;
    padding: 15px 0px;
  }

  .type {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px 0px;
    margin-top: 0px;

    .wx,
    .alipay {
      text-align: center;

      div:first-child {
        border: 1px solid #777777;
        padding: 5px;

        img {
          width: 228px;
          height: 228px;
        }
      }

      div:last-child {
        margin-top: 17px;
        text-align: center;
        font-size: 16px;

        font-weight: 400;
        color: #000000;

        img {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }

    .wx {
      // margin-right: 70px;
    }
  }
}

.fabumainboxpay {
  text-align: center;

  .fabumainboxpay-titlef {
    .fabumainboxpay-t {
      font-size: 17px;
      font-weight: 400;
      color: #333333;
    }

    .fabumainboxpay-p {
      font-size: 22px;
      font-weight: 400;
      color: #ef0000;
      margin-top: 10px;
    }
  }

  .fabumainboxpay-pay {
    border-top: 1px solid #eeeeee;
    margin-top: 20px;
    padding-top: 31px;

    .fabumainboxpay-pay-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 27px;

      .wximg {
        width: 27px;
        height: 27px;
        margin-right: 27px;
      }

      div {
        font-size: 13px;
        font-weight: 400;
        color: #333333;

        span {
          font-size: 12px;
          color: #777777;
          margin-left: 13px;
        }
      }

      .chooseimg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.dialog-footer-ggpay {
  .el-button {
    width: 40%;
    padding: 15px;
  }
}

.dialog-footer-gg1 {
  .btnbox {
    width: 160px;
    margin: 0 10px;
    height: 45px;
    line-height: 45px;
  }

  .cancelbtn {
    background: #f4f4f4;
    color: #4c4a48;
  }
}
</style>
