<template>
  <div style="border-top: undefined solid #ebebeb; padding-top: 4.94505vw">
    <div class="blogs-details">
      <div class="pageHead">
        <div>
          <div class="back" @click="backTo()">{{ isChinese() ? '返回博客' : 'Back To Blog' }}</div>
          <div class="title">{{ obj.title }}</div>
          <div class="author">{{ isChinese() ? '作者' : 'Author' }}：{{ obj.author }}</div>
          <div class="time">{{ obj.createTime | calculate }}</div>
        </div>
        <img :src="obj.cover" alt="" mode="widthFix" />
      </div>
      <div style="overflow: hidden" v-html="obj.content"></div>
      <div class="cared">
        <div class="left" v-if="JSON.stringify(lastObj) != '{}'">
          <div class="back" @click="getBlogsDetail(lastObj.id)">{{ isChinese() ? '上一个故事' : 'Previous story' }}</div>
          <div class="div">
            <div class="img">
              <img :src="lastObj.cover" alt=" " mode="widthFix" />
            </div>

            <div class="view-div">
              <div class="time">{{ lastObj.createTime | calculate }}</div>
              <h3>{{ lastObj.title }}</h3>
              <div class="intro">
                {{ isChinese() ? lastObj.intro : lastObj.introEng }}
              </div>
            </div>
          </div>
          <div class="tagA" @click="getBlogsDetail(lastObj.id)">{{ isChinese() ? '查看更多' : 'View More' }}</div>
        </div>
        <div class="left" v-if="JSON.stringify(nexObj) != '{}'">
          <div class="back" @click="getBlogsDetail(nexObj.id)">{{ isChinese() ? '下一个故事' : 'Next story' }}</div>
          <div class="div">
            <div class="img">
              <img :src="nexObj.cover" alt="" mode="aspectFill" />
            </div>

            <div class="view-div">
              <div class="time">{{ nexObj.createTime | calculate }}</div>
              <h3>{{ nexObj.title }}</h3>
              <div class="intro">
                {{ isChinese() ? nexObj.intro : nexObj.introEng }}
              </div>
            </div>
          </div>
          <div class="tagA" @click="getBlogsDetail(nexObj.id)">{{ isChinese() ? '查看更多' : 'View More' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBlogsDetail } from '@/api/main.js'
export default {
  data() {
    return {
      blogsId: '',
      obj: {},
      lastObj: {},
      nexObj: {}
    }
  },
  mounted() {
    this.blogsId = this.$route.query.id
    this.getBlogsDetail(this.blogsId)
  },
  filters: {
    calculate(time) {
      if (time) {
        return time.split(' ')[0]
      } else {
        return ''
      }
    }
  },
  methods: {
    getBlogsDetail(id) {
      getBlogsDetail({
        blogsId: id
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.obj = res.data
          } else {
            this.obj = {
              ...res.data,
              author: res.data.authorEng,
              content: res.data.contentEng,
              title: res.data.titleEng
            }
          }
          if (res.data.lastId) {
            this.lastObj = {}
            this.lastNex(res.data.lastId, 'last')
          }
          if (res.data.nextId) {
            this.nexObj = {}
            this.lastNex(res.data.nextId, 'next')
          }
        } else {
          this.showMessage()
        }
      })
    },
    lastNex(id, type) {
      getBlogsDetail({
        blogsId: id
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            if (type == 'last') {
              this.nexObj = res.data
            } else {
              this.lastObj = res.data
            }
          } else {
            if (type == 'last') {
              this.nexObj = {
                ...res.data,
                author: res.data.authorEng,
                content: res.data.contentEng,
                title: res.data.titleEng
              }
            } else {
              this.lastObj = {
                ...res.data,
                author: res.data.authorEng,
                content: res.data.contentEng,
                title: res.data.titleEng
              }
            }
          }
        } else {
          this.showMessage()
        }
      })
    },
    backTo() {
      this.$router.back('id=12')
    }
  }
}
</script>
<style lang="scss" scoped>
.blogs-details {
  width: 1200px;
  margin: auto;
  border-top: 1px solid #ebebeb;
  padding: 0px 0px 50px 0px;

  .pageHead {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    height: 460px;
    overflow: hidden;

    img {
      width: 690px;
      height: 460px;
      object-fit: cover;
    }

    div {
      width: 500px;

      .back {
        text-decoration: underline;
        font-size: 16px;
        //
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin-top: 40px;
        cursor: pointer;
      }

      .title {
        font-size: 56px;
        //
        font-weight: 400;
        color: #333333;
        line-height: 78px;
        margin-top: 80px;
      }

      .author {
        font-size: 18px;
        //
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        margin-top: 20px;
      }

      .time {
        font-size: 18px;
        //
        font-weight: 400;
        color: #333333;
        line-height: 25px;
      }
    }
  }

  .cared {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    flex-wrap: wrap;
    margin-top: 125px;

    .back {
      text-decoration: underline;
      margin-bottom: 30px;
      font-size: 18px;
      //
      font-weight: 400;
      color: #333333;
      cursor: pointer;
    }

    .back:hover {
      color: #a1a1a1;
    }

    .left {

      // margin-right: 20px;
      .tagA {
        font-size: 14px;
        //
        font-weight: 400;
        color: #333333;
        text-decoration: underline;
        padding: 20px 0px;
        cursor: pointer;
      }

      .tagA:hover {
        color: #a1a1a1;
      }
    }

    .left:nth-child(3n) {
      margin-right: 0px;
    }

    .div {
      position: relative;

      .img {
        img {
          width: 580px;
          height: 368px;
          object-fit: cover;
        }

        overflow: hidden;
      }

      width: 580px;
    }

    .div:hover {
      .view-div {
        bottom: 0px;
      }
    }

    .view-div {
      width: 100%;
      background-color: #fff;
      padding: 20px 0px;
      transition: bottom 0.5s ease 0.1s;
      padding-bottom: 0px;

      .time {
        font-size: 14px;
        //
        font-weight: 400;
        color: #333333;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 20px;
        //
      }

      .intro {
        color: #333333;
        padding: 16px 0px;
        padding-bottom: 0px;
        font-size: 16px;
        //
        font-weight: 400;
        line-height: 22px;
        height: 60px;
        overflow: hidden;
      }

      .buttom {
        width: 168px;
        padding: 8px 0px;
        background: #ffffff;
        border: 1px solid #333333;
        font-size: 14px;
        text-align: center;
        //
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }

      .buttom:hover {
        background: #000;
        color: #fff;
        transition: background 1s ease 0.1s, color 1s ease 0.1s;
      }
    }
  }
}
</style>
