<template>
  <div class="productA">
    <div class="product-img">
      <div class="img">
        <img :src="product.cover" alt="" />
      </div>
      <div class="hoverView">
        <img :src="product.cover" alt="" />
        <div class="buttom" @click="selectProduct(product)">{{ isChinese() ? '立刻购买' : 'Buy Now' }}</div>
      </div>
    </div>
    <div class="product-sum">
      <div class="name">
        <span>{{ isChinese() ? product.name : product.nameEng }}</span>
        <span>¥{{  Number(product.price).toFixed(2) }}</span>
      </div>
      <div class="bot">
        <span style="font-size: 0.76923vw">{{ isChinese() ? product.classifyName : product.classifyNameEng }}</span>
        <span class="hover" style="font-size: 0.76923vw" @click="selectProduct(product)">{{ isChinese() ? '去购买' : 'Go to buy' }}</span>
      </div>
      <div class="colors" v-if="initialStyle == 'productSku'">
        <div v-for="(item, index) of 4" :key="index"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    initialStyle: {
      type: String,
      default: 'search' //搜索页  index首页 productSku 商品分类页
    },
    product: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    selectProduct(item) {
      this.$router.push({ path: 'productDetails', query: { id: item.id } })
    }
  }
}
</script>
<style lang="scss" scoped>
.productA {
  width: 405px;
  //   height: 100%;
  margin: 15px;
  .product-img {
    //width: 280px;
    width: 100%;
    // height: 533px;
    position: relative;

    .img {
      position: absolute;
      // height: 100%;
      width: 100%;
      display: block;

      img {
        width: 100%;
        // height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .hoverView {
      position: relative;
      opacity: 0;
      height: 100%;
      img {
        width: 100%;
        //height: 280px;
        height: 100%;
        object-fit: cover;
      }

      .buttom {
        // width: 188px;
        width: 168px;
        padding: 8px 0px;
        background: #ffffff;
        border: 1px solid #333333;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30px;
        font-size: 14px;
        text-align: center;
        //
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }

      .buttom:hover {
        background: #000;
        color: #fff;
        transition: background 1s ease 0.1s, color 1s ease 0.1s;
      }
    }
  }

  .product-img:hover {
    .hoverView {
      // display: block;
      opacity: 1;
      transition: opacity 1s ease 0.1s;
    }

    .img {
      // display: none;
      opacity: 0;
      transition: opacity 1s ease 0.1s;
    }
  }

  .product-sum {
    margin-top: 25px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 24px;
    }

    .name {
      span {
        font-size: 18px;
        //
        font-weight: 400;
        color: #333333;
      }
    }

    .bot > span:nth-child(2) {
      font-size: 12px;
      //
      font-weight: 400;
      color: #666666;
    }

    .colors {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        width: 32px;
        height: 32px;
        background: #eb6036;
        margin-right: 10px;
      }
    }
  }

  .hover {
    cursor: pointer;
  }
}
</style>
