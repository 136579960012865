<template>
  <div class="question pageWapper">
    <div class="question-class">
      <div class="item" v-for="(item, index) of questionList" :key="index" @click="setings(item, index)">
        <img :src="item.icon" alt="" />
        <div :class="index == setingIndex ? 'active' : ''">{{ isChinese() ? item.name : item.nameEng }}</div>
      </div>
    </div>
    <div class="panel">
      <div class="card">
        <h3>{{ title }}</h3>
        <el-collapse accordion>
          <el-collapse-item v-for="(item, index) of FaqDetail" :key="index">
            <template slot="title">
              <div class="icont"></div>
              {{ isChinese() ? item.title : item.titleEng }}
            </template>
            <div class="text">{{ isChinese() ? item.content : item.contentEng }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { listFaqType, listFaqDetail } from '@/api/main.js'
export default {
  data() {
    return {
      setingIndex: 0,
      questionList: [],
      FaqDetail: [],
      title: ''
    }
  },
  mounted() {
    this.listFaqType()
  },
  methods: {
    listFaqType() {
      listFaqType({}).then((res) => {
        if (res.code == 0) {
          this.questionList = res.data
          this.title = this.isChinese() ? res.data[0].name : res.data[0].nameEng
          this.listFaqDetail(res.data[0].id)
        } else {
          this.showMessage()
        }
      })
    },
    listFaqDetail(typeId) {
      listFaqDetail({
        typeId
      }).then((res) => {
        if (res.code == 0) {
          this.FaqDetail = res.data
        } else {
          this.showMessage()
        }
      })
    },
    setings(item, index) {
      this.setingIndex = index
      this.title = this.isChinese() ? item.name : item.nameEng
      this.listFaqDetail(item.id)
    }
  }
}
</script>
<style lang="scss">
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 90px;
}
.question {
  .question-class {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background: #f9f9f9;
    .item {
      width: 200px;
      height: 130px;
      background: #ffffff;
      text-align: center;
      padding: 12px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-right: 10px;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
      }
      div {
        font-size: 17px;
        //
        font-weight: 400;

        text-align: center;
      }
      .active {
        color: #ff0000;
      }
    }
  }
  .panel {
    padding: 40px 0px;
    background: #ffffff;
    .card {
      margin: 0 auto;

      h3 {
        color: #ff0000;
        margin-bottom: 20px;
      }
      .icont {
        width: 12px;
        height: 12px;
        background: #cccccc;
        border-radius: 50%;
        margin-right: 10px;
        margin-left: 20px;
      }
      .text {
        padding: 10px 52px;
        box-sizing: border-box;
      }
      .el-collapse-item__header {
        margin-top: 10px;
        border: none;
        background-color: #f9f9f9;
        font-weight: 700;
        box-sizing: border-box;
      }
    }
  }
}
</style>
