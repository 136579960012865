<template>
  <div class="mine pageWapper">
    <div class="boxs">
      <div class="left">
        <div @click="navTo(index)" :class="index == mineIndex ? 'active' : ''" v-for="(item, index) of $t('menu.about')" :key="index">{{ item }}</div>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mineIndex: 0
    }
  },
  mounted() {
    console.log('path:', this.$route.path)
    this.initActive()
  },
  updated() {
    console.log('path:', this.$route.path)
    this.initActive()
  },
  methods: {
    initActive() {
      this.getPath(2)
    },
    getPath(type, index) {
      var path = {
        0: '/About/introduction',
        // 1: '/About/brand',
        1: '/About/ShoppingInstructions',
        2: '/About/leaveAMessage',
        3: '/About/privacy'
      }
      if (type == 1) {
        return path[index]
      } else {
        Object.keys(path).forEach((index) => {
          if (path[index] == this.$route.path) {
            this.mineIndex = index
          }
        })
      }
    },
    navTo(index) {
      if (this.mineIndex == index) {
        return
      }
      this.mineIndex = index
      this.$router.push({ path: this.getPath(1, index) })
    }
  }
}
</script>
<style lang="scss">
.pageWapper {
  width: 100vw;
  // max-width: 1700px;
  margin: 0 auto;
  padding: 30px 0;
}
.mine {
  padding: 40px 0px;
  padding-top: 90px;
  .boxs {
    justify-content: center;
    display: flex;
    align-items: flex-start;
    .left {
      // margin-right: 180rpx;
      div {
        width: 280px;
        height: 40px;
        padding: 9px 0px;
        font-size: 16px;
        //
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }
      .active {
        background: #f8f8f8;
        transition: background 0.2s ease 0.2s;
      }
    }
    .right {
      padding-left: 40px;
    }
  }
}
.boxs .right .content {
  padding-top: 0 !important;
}
</style>
