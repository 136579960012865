<template>
  <div class="pageWapper">
    <div>
      <h1 style="margin-bottom: 1.64835vw; text-align: center">{{ activeInfo.title }}</h1>
      <div class="richTextWapper" v-html="activeInfo.content"></div>
    </div>
    <!-- <div class="addbtn">{{$t('button.baoming')}}</div> -->
  </div>
</template>

<script>
import { getActivityInfo } from '@/api/main.js'
export default {
  data() {
    return {
      activeInfo: {}
    }
  },
  mounted() {
    this.getActivityInfo()
  },
  methods: {
    getActivityInfo() {
      getActivityInfo({}).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.activeInfo = res.data
          } else {
            this.activeInfo = {
              ...res.data,
              title: res.data.titleEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding: 30px 0;
  padding-top: 120px;

  .richTextWapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }
}

.addbtn {
  margin-top: 30px;
  height: 50px;
  margin-right: 20px;
  // width: 210px;
  // height: 50px;
  border: 1.5px solid transparent;
  border-radius: 0;
  white-space: nowrap;
  transition: all 0.3s ease;
  border: 2px solid #000;
  text-align: center;
  padding: 15px 65px;
}

.addbtn:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}
</style>
