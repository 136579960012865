<template>
  <div class="pageWapper">
    <h1 style="margin-bottom: 1.64835vw; text-align: center">{{ activeInfo.title }}</h1>
    <div class="richTextWapper" v-html="activeInfo.content"></div>
  </div>
</template>

<script>
import { getRecruitmentInfo } from '@/api/main.js'
export default {
  data() {
    return {
      activeInfo: {}
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    getPageData() {
      getRecruitmentInfo({}).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.activeInfo = res.data
          } else {
            this.activeInfo = {
              ...res.data,
              title: res.data.titleEng,
              content: res.data.contentEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding: 30px 0;
  padding-top: 90px;

  .richTextWapper {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }
}
</style>
