<template>
  <div class="mine">
    <div class="boxs">
      <div class="left">
        <div @click="navTo(index)" :class="index == mineIndex ? 'active' : ''" v-for="(item, index) of $t('mine.menuList2')" :key="index">{{ item }}</div>
      </div>
      <div class="right">
        <!-- <keep-alive include="orderList"> -->
        <router-view></router-view>
        <!-- </keep-alive> -->
      </div>
    </div>
  </div>
</template>

<script>
import $bus from '@/util/bus.js'
export default {
  data() {
    return {
      mineIndex: 0
    }
  },
  mounted() {
    this.initActive()
    $bus.$on('updateRouter', this.initActive) //页头也跳转过来
  },
  beforeDestroy() {
    $bus.$off('updateRouter')
  },
  methods: {
    initActive() {
      this.getPath(2)
    },
    getPath(type, index) {
      var path = {
        0: '/mine/userInfo',
        1: '/mine/favorite',
        2: '/mine/shoppingCart',
        3: '/mine/orderList',
        4: '/mine/vipGrade',
        5: '/mine/message',
        6: '/mine/setingPassword'
      }
      if (type == 1) {
        return path[index]
      } else {
        const pathArr = ['/mine/orderDetails', '/mine/orderScore', '/mine/afterSale', '/mine/lookApply']
        if (pathArr.includes(this.$route.path)) {
          // console.log('router:', this.$route.path)
          this.mineIndex = 3
        } else {
          Object.keys(path).forEach((index) => {
            if (path[index] == this.$route.path) {
              this.mineIndex = index
            }
          })
        }
      }
    },
    navTo(index) {
      if (this.mineIndex == index) {
        return
      }
      this.mineIndex = index
      this.$router.push({ path: this.getPath(1, index) })
    }
  }
}
</script>
<style lang="scss">
.mine {
  width: 100vw;
  padding: 40px 0px;
  padding-top: 90px;
  display: flex;
  // justify-content: center;
  .boxs {
    padding-left: 310px;
    min-width: 1200px;
    // margin: 0 auto;
    // justify-content: space-between;
    display: flex;
    align-items: flex-start;
    .left {
      div {
        width: 280px;
        height: 40px;
        padding: 9px 0px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }
      .active {
        background: #f8f8f8;
        transition: background 0.2s ease 0.2s;
      }
    }
  }
}
.right {
  padding-left: 40px;
}
.boxs .right > div {
  padding-top: 0 !important;
}
</style>
