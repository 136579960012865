<template>
  <div class="message">
    <div class="tilte">
      <div></div>
      <span>{{ $t('mine.menuList[5]') }}</span>
    </div>
    <div class="tabeList">
      <div class="list" @click="cliceTab(index)" :class="taberIndex == index ? 'active' : ''" v-for="(item, index) of $t('mine.messge.list')" :key="index">{{ item }}</div>
    </div>
    <div class="msg">
      <div class="system" v-if="taberIndex == 0">
        <div class="msgList" v-for="(item, index) of messageList" :key="index">
          <div>
            <img src="../../../public/imgs/mine/mess.png" alt="" />
            <div class="info">
              <div class="title">
                {{ item.title }} <span> {{ timeago(item.createTime) }}</span>
              </div>
              <div v-html="item.content"></div>
            </div>
          </div>
          <i class="el-icon-close" @click="deltMessage(item, index)"></i>
        </div>
        <div class="empty" v-if="messageList.length == 0">
          <empty :title="isChinese() ? '暂无系统通知' : 'No Data'"></empty>
        </div>
      </div>
      <div class="warn" v-else>
        <div class="msgWarn" v-for="(item, index) of messageList" :key="index">
          <div class="title">
            <div>{{ item.title }}</div>
            <span>{{ timeago(item.createTime) }}</span>
          </div>
          <div style="color: #666666; height: 1.0989vw; margin: 0.54945vw undefined">{{ item.content }}</div>
          <div class="goods">
            <img class="pic" :src="item.goodsCover" alt="" />
            <div class="info">
              <div class="name">
                {{ item.goodsName }}
              </div>
              <div class="sku">
                <!-- <span>颜色：</span> 紫色 -->
              </div>
            </div>
          </div>
          <div class="after" @click="navTo">
            {{ $t('button.chakna') }}
            <i class="el-icon-arrow-right"></i>
            <!-- @click="deltMessage(item, index)" -->
          </div>
        </div>
        <div class="empty" v-if="messageList.length == 0">
          <empty :title="isChinese() ? '暂无系统通知' : 'No Data'"></empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listMessage, deleteMessage } from '@/api/main.js'
import empty from '@/components/empty.vue'
import $bus from '@/util/bus.js'
export default {
  components: { empty },
  data() {
    return {
      taberList: ['系统通知', '到货提醒'],
      taberIndex: 0,
      pageNum: 1,
      messageList: []
    }
  },
  mounted() {
    this.getlistMessage()
  },
  methods: {
    navTo() {
      this.$router.push('/mine/favorite')
      $bus.$emit('updateRouter')
    },
    deltMessage(item, index) {
      deleteMessage({
        type: this.taberIndex + 1,
        messageId: item.id
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: this.isChinese() ? '删除成功' : 'successfully delete',
            type: 'success'
          })
          this.messageList.splice(index, 1)
        } else {
          this.showMessage(res)
        }
      })
    },
    cliceTab(index) {
      this.taberIndex = index
      this.messageList = []
      this.getlistMessage()
    },
    getlistMessage() {
      listMessage({
        type: this.taberIndex + 1,
        pageNum: this.pageNum,
        pageSize: 100
      }).then((res) => {
        if (res.code == 0) {
          console.log(res)
          if (this.isChinese()) {
            this.messageList = res.data.rows
          } else {
            this.messageList = res.data.rows.filter((data) => {
              ;(data.content = data.contentEng), (data.title = data.titleEng), (data.goodsName = data.goodsNameEng)
              return data
            })
          }
        } else {
          this.showMessage(res)
        }
      })
    },
    timeago(dateTimeStamp) {
      dateTimeStamp = new Date(dateTimeStamp).getTime()
      function addZeroPrefix(number) {
        return number < 10 ? `0${number}` : number
      }
      var yuyan = this.isChinese()
      // dateTimeStamp 时间戳
      var minute = 1000 * 60 //把分，时，天，周，半个月，一个月用毫秒表示
      var hour = minute * 60
      var day = hour * 24
      var week = day * 7
      var halfamonth = day * 15
      var month = day * 30
      var now = new Date().getTime() //获取当前时间毫秒
      var diffValue = now - dateTimeStamp //时间差

      if (diffValue < 0) {
        return
      }
      var minC = diffValue / minute //计算时间差的分，时，天，周，月
      var hourC = diffValue / hour
      var dayC = diffValue / day
      var weekC = diffValue / week
      var monthC = diffValue / month
      var result = ''
      let a = ''
      if (monthC >= 1 && monthC <= 3) {
        if (yuyan) {
          a = '月前'
        } else {
          a = 'Month ago'
        }
        result = parseInt(monthC) + a
      } else if (weekC >= 1 && weekC <= 3) {
        if (yuyan) {
          a = '周前'
        } else {
          a = 'Weeks ago'
        }
        result = parseInt(weekC) + a
      } else if (dayC >= 1 && dayC <= 6) {
        if (yuyan) {
          a = '天前'
        } else {
          a = 'Days ago'
        }
        result = parseInt(dayC) + a
      } else if (hourC >= 1 && hourC <= 23) {
        if (yuyan) {
          a = '小时前'
        } else {
          a = 'Hours ago'
        }
        result = parseInt(hourC) + a
      } else if (minC >= 1 && minC <= 59) {
        if (yuyan) {
          a = '分钟前'
        } else {
          a = 'minutes ago'
        }
        result = parseInt(minC) + a
      } else if (diffValue >= 0 && diffValue <= minute) {
        if (yuyan) {
          a = '刚刚'
        } else {
          a = 'just'
        }
        result = a
      } else {
        var datetime = new Date()
        datetime.setTime(dateTimeStamp)
        console.log(halfamonth)
        var Nyear = datetime.getFullYear()
        var Nmonth = addZeroPrefix(datetime.getMonth() + 1)
        var Ndate = addZeroPrefix(datetime.getDate())
        // var Nhour = addZeroPrefix(datetime.getHours());
        // var Nminute = addZeroPrefix(datetime.getMinutes());
        // var Nsecond = addZeroPrefix(datetime.getSeconds());
        result = Nyear + '-' + Nmonth + '-' + Ndate
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  width: 880px;
  padding-top: 90px;

  .tilte {
    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }

  .tabeList {
    display: flex;
    align-items: center;
    padding: 30px 30px 0px 12px;
    border-bottom: 1px solid #ececec;

    div {
      // width: 100px;
      // border:1px solid red;
      padding: 10px 15px;
      text-align: center;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      cursor: pointer;
    }

    .active {
      color: #e92d2d;
      position: relative;
    }

    .active:after {
      position: absolute;
      text-align: center;
      content: '';
      display: block;
      width: 66px;
      height: 2px;
      background: #e92d2d;
      left: 50%;
      margin-left: -33px;
      margin-top: 10px;
    }
  }

  .msg {
    .system {
      .msgList {
        padding: 30px;
        padding-left: 0px;
        border: 1px solid #ececec;
        border-left: none;
        border-right: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          display: flex;
          align-items: center;

          img {
            width: 48px;
            height: 48px;
          }

          .info {
            margin-left: 20px;
            width: 700px;

            .title {
              font-size: 16px;

              color: #333333;
              margin-bottom: 15px;

              span {
                font-size: 13px;
                font-weight: 400;
                color: #999999;
                padding-left: 15px;
              }
            }

            div {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
            }
          }
        }

        .el-icon-close {
          font-size: 20px;
        }

        .el-icon-close {
          display: none;
        }
      }

      .msgList:hover {
        background-color: #f8f8f8;

        .el-icon-close {
          display: inline-block;
        }
      }
    }

    .warn {
      .msgWarn:hover {
        background-color: #f9f9f9;
      }

      .msgWarn {
        padding: 30px 0px;
        border-bottom: 1px solid #ececec;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
          }

          span {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
        }

        .goods {
          background: #f9f9f9;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .pic {
            width: 120px;
            height: 120px;
            background: #ece8f1;
            margin-right: 20px;
          }

          .info {
            .name {
              font-size: 18px;
              font-weight: 400;
              color: #333333;
            }

            .sku {
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              margin-top: 22px;

              span {
                color: #777777;
              }
            }
          }
        }

        .after {
          cursor: pointer;
          margin-top: 15px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          text-align: right;
        }
      }
    }
  }
}
</style>
