<template>
  <div class="goods">
    <div class="tilte">
      <div></div>
      <span>{{ $t('button.see') }}</span>
    </div>
    <div style="font-weight: 700; font-size: 0.87912vw; margin-top: 1.0989vw">{{ $t('mine.elses.aftersale') }}</div>
    <div class="product">
      <div class="list" v-for="(items, index) of orderDetails.detailList" :key="index">
        <img class="pic" :src="items.cover" alt="" />
        <div class="productDatil">
          <div class="name dis">
            <span>{{ isChinese() ? items.name : items.nameEng }}</span>
            <span>¥{{ Number(items.price).toFixed(2) }}</span>
          </div>
          <div class="sku">
            <div><span></span> {{ isChinese() ? items.keyName : items.keyNameEng }}</div>
            <span>x{{ items.number }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="orderDetails">
      <div style="font-weight: 700; font-size: 0.87912vw; margin-top: 1.0989vw">{{ $t('mine.elses.jianshu') }}</div>
      <div>
        <span>{{ orderDetails.afterReason }}</span>
      </div>
      <div style="font-weight: 700; font-size: 0.87912vw; margin-top: 1.0989vw">{{ $t('mine.elses.miaoshu') }}</div>
      <div>
        <span>{{ orderDetails.afterDescription }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderDetails } from '@/api/main.js'
export default {
  data() {
    return {
      indexStatus: 0, //0待支付，1待发货，2待收货，3待评价，4已完成，5已取消，6退款/售后申请，7审核成功，8审核失败，9已退款。默认0
      orderId: '',
      orderDetails: {}
    }
  },
  mounted() {
    this.orderId = this.$route.query.id
    this.getOrderDetails()
  },
  methods: {
    getOrderDetails() {
      getOrderDetails({
        orderId: this.orderId
      }).then((res) => {
        if (res.code == 0) {
          this.orderDetails = res.data
        } else {
          this.showMessage(res)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.goods {
  width: 880px;
  padding-top: 90px;
  .tilte {
    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }

  .product {
    background: #f9f9f9;
    padding: 16px;
    margin: 20px 0px;
    margin-top: 10px;
    .title {
      font-size: 18px;
      font-weight: 700;
      color: #333333;
    }
    .list {
      display: flex;
      justify-content: space-between;
      .pic {
        width: 120px;
        height: 120px;
        background: #ece8f1;
      }

      .productDatil {
        width: 700px;

        .dis {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .name {
          span {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
          }

          i {
            font-size: 25px;
          }
        }

        .sku {
          padding: 22px 0px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }

        .del,
        .sku {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }

        .del {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .input-number {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 90px;
          height: 30px;
          background: #f2f2f2;
          padding: 0px 5px;

          div {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
          }

          .font {
            font-size: 19px;
            color: #000000;
          }
        }
      }
    }
  }
  .address {
    padding: 30px 0px;
    border-bottom: 1px solid #eeeeee;
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
    }
    .str {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-top: 16px;
    }
  }
  .orderDetails {
    padding: 30px 0px;
    > div {
      display: flex;
      align-items: center;
      padding: 6px 0px;
      span {
        font-size: 15px;
        font-weight: 400;
        color: #666666;
      }
      div {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        margin-left: 20px;
      }
    }
  }
}
</style>
