<template>
  <div class="bodys">
    <topHead :closeChat="closeChat" :hidderChat="hidderChat"></topHead>
    <!-- ,/ -->
    <keep-alive include="blogs">
      <router-view :key="$route.name + ($route.query.id || '')" />
    </keep-alive>
    <div class="postaber">
      <img @click="toGo('https://www.instagram.com/yocamily_outdoor?igsh=MW9tbDN1d3c1NnN6bg==')" src="../../public/imgs/ins.png" alt="" />
      <img @click="toGo('https://www.youtube.com/channel/UCRnkln0mgvBTFcljhFJjFzA')" src="../../public/imgs/youtube.png" alt="" />
      <img @click="toGo('https://www.xiaohongshu.com/user/profile/58908a2982ec3925d302b4fc?xhsshare=CopyLink&appuid=58908a2982ec3925d302b4fc&apptime=1703473480')" src="../../public/imgs/redbook.png" alt="" />
      <img @click="toGo('https://weibo.com/u/7887685910')" src="../../public/imgs/wb.png" alt="" />
      <img @click="toCustomerService()" src="../../public/imgs/sv.png" alt="" />
      <img @click="scrollToTop()" src="../../public/imgs/up.png" alt="" style="margin-top: 0.32967vw" />
    </div>
    <footers></footers>
    <div class="shoppingTrolley" v-if="showChat" @click.stop="hidderChat">
      <div class="cart">
        <div class="head-chat">
          <span>{{ $t('mine.menuList[2]') }}</span>
          <i @click.stop="closeChat" class="el-icon-close"></i>
        </div>
        <div class="cart-list">
          <div class="list" v-for="(item, index) of shoppingCartList" :key="index">
            <img :src="item.cover" alt="" />
            <div class="right">
              <div class="detaul">
                <div class="name">{{ isChinese() ? item.name : item.nameEng }}</div>
                <span>¥{{ Number(item.price).toFixed(2)}}</span>
              </div>
              <div class="sku">
                <!-- 颜色： <span>紫色</span> -->
                {{ isChinese() ? item.keyName : item.keyNameEng }}
              </div>
              <div class="addDelceCact">
                <span class="del" @click.stop="deleteCar(item, index)">{{ isChinese() ? '删除' : 'Delete' }}</span>
                <div class="input-number">
                  <div class="font" @click.stop="subtract(item, index)">-</div>
                  <div>{{ item.number }}</div>
                  <div class="font" @click.stop="add(item, index)">+</div>
                </div>
              </div>
            </div>
          </div>
          <empty v-if="shoppingCartList.length == 0" :title="isChinese() ? '购物车为空' : 'Shopping cart is empty.'"></empty>
        </div>
        <div class="total">
          <span> {{ $t('mine.elses.heji') }}： </span>
          <span> ¥{{ totalPic.toFixed(2) }} </span>
        </div>
        <div class="button" @click.stop="confirmOrder">
          {{ $t('button.lijitijao') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topHead from './head/index.vue'
import footers from './footer/index.vue'
import $bus from '@/util/bus.js'
import { listUserShoppingCart, updateShoppingCart, deleteShoppingCart } from '@/api/main.js'
import empty from '@/components/empty.vue'
import { mapState } from 'vuex'
export default {
  name: 'nav-home',
  components: { topHead, footers, empty },
  data() {
    return {
      showChat: false,
      shoppingCartList: [], //购车
      isLoding: true,
      totalPic: 0 //商品总价
    }
  },
  computed:{
    ...mapState(['userInfo'])
  },
  mounted() {
    console.log('???:', this.$route)
    //this.listUserShoppingCart()//获取购物车
    $bus.$on('updateChat', this.updataListUserShopingCart) //更新购物车
  },
  methods: {
    hidderChat() {
      this.showChat = false
    },
    toCustomerService() {
      // http://workerman.yocamily.com/addons/kefu/kefu/index?user_id=100&user_name=hejia&email=499470062@qq.com&telphone=16620589258
      // window.open('http://workerman.yocamily.com/addons/kefu/index/index')
      
      if(this.userInfo&&this.userInfo.id){
        const link=`http://workerman.yocamily.com/addons/kefu/kefu/index?user_id=${this.userInfo.id}&user_name=${this.userInfo.nickName||''}&email=${this.userInfo.eMail||''}&telphone=${this.userInfo.phoneNumber||''}`
        console.log(encodeURI(encodeURI(link)));
				window.open(encodeURI(encodeURI(link)))
      }else{
        window.open('http://workerman.yocamily.com/addons/kefu/index/index')
      }
    },
    toGo(link) {
      if(link){
        window.open(link)
        return
      }
      this.$message(this.isChinese() ? '功能正在准备中,敬请期待' : 'Not yet enabled.')
    },
    //提交订单
    confirmOrder() {
      if (this.shoppingCartList.length == 0) {
        this.$message({
          type: 'warning',
          message: this.isChinese() ? '购物车为空' : 'Shopping cart is empty.'
        })
        return
      }
      this.showChat = false
      this.$router.push({ name: 'submit', params: { cartList: JSON.stringify(this.shoppingCartList) } })
    },
    deleteCar(item, index) {
      this.showChat = false
      var obj = {}
      if (this.isChinese()) {
        obj = {
          title: '您确定要删除？',
          del: '删除',
          confirm: '确定',
          cancel: '取消'
        }
      } else {
        obj = {
          title: 'Are you sure you want to delete？',
          del: 'Delete',
          confirm: 'Confirm',
          cancel: 'Cancel'
        }
      }
      this.$confirm(obj.title, obj.del, {
        confirmButtonText: obj.confirm,
        cancelButtonText: obj.cancel,
        type: 'warning'
      })
        .then(() => {
          this.showChat = true
          this.deleteShoppingCart(item, index)
        })
        .catch(() => {
          this.showChat = true
        })
    },
    //删除购物车商品
    deleteShoppingCart(item, index) {
      deleteShoppingCart({
        shoppingCartId: item.id
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: this.isChinese() ? '删除成功' : 'Successfully delete'
          })
          this.shoppingCartList.splice(index, 1)
          this.updateTotalPic() //更新总价
        } else {
          this.showMessage()
        }
      })
    },
    subtract(item, index) {
      //数量减
      if (!this.isLoding) return

      if (item.number == 1) {
        //如果数量为1在删除那就清除这个商品
        this.deleteShoppingCart(item, index)
        return
      }
      this.isLoding = false
      var nber = item.number
      updateShoppingCart({
        id: item.id,
        number: nber - 1
      }).then((res) => {
        this.isLoding = true
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber - 1
          this.updateTotalPic() //更新总价
        } else {
          this.showMessage()
        }
      })
    },
    add(item, index) {
      //数量加
      if (!this.isLoding) return
      this.isLoding = false
      var nber = item.number
      updateShoppingCart({
        id: item.id,
        number: nber + 1
      }).then((res) => {
        this.isLoding = true
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber + 1
          this.updateTotalPic() //更新总价
        } else {
          this.showMessage()
        }
      })
    },
    updataListUserShopingCart() {
      //
      this.listUserShoppingCart()
      this.showChat = true
    },
    listUserShoppingCart() {
      //获取购物车
      listUserShoppingCart({}).then((res) => {
        if (res.code == 0) {
          this.shoppingCartList = res.data
          this.updateTotalPic()
        } else {
          this.showMessage()
        }
      })
    },
    updateTotalPic() {
      //计算价格
      let bigPrice = 0
      this.shoppingCartList.find((item) => {
        bigPrice += item.price * item.number + item.freight //freight 运费不知道加不加
      })
      this.totalPic = bigPrice
    },
    closeChat() {
      let token = localStorage.getItem('token')
      if (token) {
        this.listUserShoppingCart()
        this.showChat = !this.showChat
      } else {
        this.$router.push('/login')
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.bodys {
  overflow-x: hidden;
  background-color: #ffff;
}

.shoppingTrolley {
  right: 0px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0px;
  background: rgba(0, 0, 0, 0.7);

  .cart {
    width: 450px;
    height: 664px;
    background: #f9f9f9;
    position: absolute;
    right: 0px;
    top: 0px;

    .head-chat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #ececec;
      font-size: 18px;
      //
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;

      i {
        font-size: 25px;
      }
    }

    .cart-list {
      padding: 0px 36px;
      height: 435px;
      overflow: hidden;
      overflow-y: auto;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ececec;
        padding: 25px 0px;

        img {
          width: 110px;
          height: 138px;
          background: #ffffff;
          object-fit: cover;
        }

        .right {
          /* width: 250px; */
          flex: 1;
          padding-left: 16px;

          .detaul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0px;
            font-size: 18px;
            //
            font-weight: 400;
            color: #333333;
          }

          .sku {
            padding: 12px 0px;
            font-size: 14px;
            //
            font-weight: 400;
            color: #777777;
            word-break: break-word;

            span {
              font-size: 16px;
              //
              font-weight: 400;
              color: #333333;
            }
          }

          .addDelceCact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0px;

            .del {
              font-size: 16px;
              //
              font-weight: 400;
              color: #aaaaaa;
              cursor: pointer;
            }

            .input-number {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 80px;
              height: 30px;
              background: #f2f2f2;
              padding: 0px 5px;
              cursor: pointer;

              div {
                font-size: 16px;
                //
                font-weight: 400;
                color: #000000;
                line-height: 22px;
              }

              .font {
                font-size: 25px;
              }
            }
          }
        }
      }
    }

    .total {
      border-top: 1px solid #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      font-size: 18px;
      //
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;
    }

    .button {
      width: 390px;
      background: #000000;
      font-size: 18px;
      //
      font-weight: 400;
      color: #dddd;
      line-height: 25px;
      padding: 15px 0px;
      text-align: center;
      margin: 15px auto;
      border: 1px solid #000;
      cursor: pointer;
    }

    .button:hover {
      color: #000000;
      background: #ffff;
      border: 1px solid #000;
      transition: color 1s ease 0.1s, background 1s ease 0.1s;
    }
  }
}

.postaber {
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 44pt * 0.8;
    height: 44pt * 0.8;
    margin-top: 8px;
  }

  img:last-child {
    width: 56pt * 0.8;
    height: 56pt * 0.8;
    margin-top: 0;
  }
}
</style>
