<template>
  <div>
    <div class="searchList">
      <div class="boxTop">
        <div class="leng">
          <span>{{ isChinese() ? '搜索结果' : 'Search result' }}</span>
          <div class="pages">
            {{ isChinese() ? `共${total}项商品` : `${total}   Items` }}
          </div>
        </div>
      </div>
      <div class="product-List" v-if="true">
        <productList :product="item" v-for="(item, index) of productList" :key="index"></productList>
        <!-- <div v-for="i in 3 - (productList.length % 3)" :key="i" class="ttttt"></div> -->
      </div>

      <div class="empty" v-if="productList.length == 0">
        <img :src="isChinese() ? require('../../../public/imgs/empty.png') : require('../../../public/imgs/emptyEeg.png')" alt="" />
      </div>
      <div class="pagination" v-if="total != 0">
        <div class="pages">
          <div class="dis">
            <!--  -->
            <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 1.64835vw" :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
            <div>{{ pageNum }} / {{ Math.ceil(total / 10) }}</div>
            <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 1.64835vw" :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import productList from '@/components/productList.vue'
import $bus from '@/util/bus.js'
import { listGoods } from '@/api/main.js'
export default {
  components: { productList },
  data() {
    return {
      styleOBj: {},
      pageNum: 1,
      productList: [],
      total: 0,
      searchText: ''
    }
  },
  mounted() {
    this.search(this.$route.params)
    $bus.$on('search', this.search)
  },
  methods: {
    search(e) {
      this.searchText = e.textKey
      this.pageNum = 1
      this.productList = []
      this.getlistGoods()
    },
    getlistGoods() {
      listGoods({
        // tuijianStatus:0,
        name: this.searchText,
        pageNum: this.pageNum,
        pageSize: 10
      }).then((res) => {
        if (res.code == 0) {
          this.total = res.data.total
          this.productList.push(...res.data.rows)
        } else {
          this.showMessage()
        }
      })
    },
    clickLeft() {
      //上一页
      if (this.pageNum <= 1) return
      this.pageNum--
      this.getlistGoods()
    },
    clickRigth() {
      //下一页
      if (this.pageNum >= Math.ceil(this.total / 10)) return
      this.pageNum++
      this.getlistGoods()
    }
  },
  beforeDestroy() {
    $bus.$off('search')
  }
}
</script>

<style lang="scss" scoped>
.searchList {
  border-top: 1px solid #ebebeb;
  background: #f9f9f9;
  padding-top: 90px;
  .product-List {
    margin: 0 auto;
    width: 1340px;
    padding: 40px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
}

.boxTop {
  background: #ffffff;
}
.leng {
  margin: 0 auto;
  width: 1200px;
  padding: 40px 0px;

  // box-shadow: inset 0px -1px 0px 0px rgba(0, 0, 0, 0.08);

  span {
    font-size: 22px;

    font-weight: 700;
    color: #333333;
  }

  .pages {
    font-size: 15px;

    font-weight: 400;
    color: #333333;
    line-height: 44px;
  }
}
.pagination {
  background-color: #ffffff;
  .pages {
    width: 1200px;
    padding: 40px 0px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    .dis {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;

      font-weight: 400;
      color: #333333;
      width: 200px;
    }
  }
}
.empty {
  text-align: center;
  width: 356px;
  height: 300px;
  img {
    width: 356px;
    height: 225px;
  }
}
</style>
