<template>
  <div>
    <div class="box pageWapper">
      <div class="sweper">
        <div class="indicatorss">
          <div class="" v-for="(item, index) of serperList" :class="indexTaber == index ? 'active' : ''" :key="index"
            @click="clickTaber(index)"></div>
        </div>
        <div style="width: 26.26374vw">
          <div class="swiper-container" style="height: 38.46154vw">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) of serperList" :key="index">
                <el-image :src="item" :preview-src-list="serperList"> </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="details">
        <div class="contents">

          <h1 class="title">{{ productDetails.name }}</h1>
          <span class="skinventory">{{ $t('details.sku') }}：{{ skuObj.stock }}</span>
          <div class="money dis">
            <div class="yyy" v-if="skuObj.originPrice && skuObj.originPrice !== skuObj.price">
              <span>
                ¥{{ skuObj.originPrice }}
              </span>
              ¥{{ skuObj.price }}
            </div>
            <div class="yyy" v-else>
              ¥{{ skuObj.price }}
            </div>
            <span>{{ $t('details.yuefei') }}：¥{{ productDetails.freight }}</span>
          </div>
          <div class="sku">
            <div v-for="(item, index) of skuList" :key="index">
              <div class="colors" v-if="item.name == '颜色'">
                <section v-for="(items, indexs) of item.specParamList" :key="indexs"
                  style="font-size: 0; margin-right: 0">
                  <img v-if="items.picture" @click="selecetSku(index, indexs, items)"
                    :class="skuIndex[index] == indexs ? 'active' : ''" :src="items.picture"
                    :title="isChinese() ? items.name : items.nameEng" alt="" />
                  <div v-else class="sxxx" :class="skuIndex[index] == indexs ? 'active' : ''"
                    @click="selecetSku(index, indexs, items)">{{ items.name }}</div>
                </section>
              </div>
              <div class="skus" v-else>
                <div @click="selecetSku(index, indexs, items)" :class="skuIndex[index] == indexs ? 'active' : ''"
                  v-for="(items, indexs) of item.specParamList" :key="indexs">
                  {{ isChinese() ? items.name : items.nameEng }}
                </div>
              </div>
            </div>
          </div>

          <div class="operator dis">
            <div class="addbtn" @click="addShoppingCart">{{ $t('button.jiaruche') }}</div>
            <div class="input-number">
              <div class="ps_container" @click="subtract">
                <svg class="subtract" width="15" height="3" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 .5v2H0v-2z" fill="#333" fill-rule="nonzero"></path>
                </svg>
              </div>
              <span class="nuber">{{ number }}</span>
              <div class="ps_container" @click="add">
                <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#333" fill-rule="nonzero">
                    <path d="M15.5 6.5v2H.5v-2z" stroke="none"></path>
                    <path d="M9 0v15H7V0z"></path>
                  </g>
                </svg>
              </div>
            </div>
            <!-- <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="描述文字"></el-input-number> -->
            <div class="dis live">
              <img @click="collect"
                :src="isLive ? require('../../../public/imgs/product/live.png') : require('../../../public/imgs/product/pitchOn-live.png')"
                alt="" />
              {{ $t('details.live') }}
            </div>
          </div>

        </div>
        <div class="info" v-html="productDetails.intro"></div>
        <!-- <img style="margin-top: 1.0989vw" :src="productDetails.detailPicture" alt="" /> -->
      </div>
    </div>

    <div class="comment pageWapper">
      <div class="tilte">{{ $t('details.pinglun') }}</div>
      <div class="score dis">
        <span class="nber">{{ productDetails.avgScore }} </span>
        <div style="height: 2.1978vw">
          <el-rate v-model="productDetails.avgScore" disabled :colors="colors" void-color="#fff" text-color="#ff9900"
            score-template="{value}"> </el-rate>
        </div>
        <div class="list">{{ total }} {{ $t('details.nuber') }}</div>
      </div>
      <div class="speakBtn dis">
        <div class="addbtn" @click="toSpeck = !toSpeck">
          <i class="el-icon-edit" style="font-size: 0.98901vw; padding-right: 0.54945vw"></i>
          {{ isChinese() ? '去评价' : 'Write A Review' }}
        </div>
      </div>
      <div :class="toSpeck ? 'speckInpuactive' : ''" class="speckInput">
        <div class="h3">{{ $t('mine.elses.pinjia') }}</div>
        <div class="title">*{{ $t('mine.elses.pinfen') }}</div>
        <div class="title">
          <el-rate v-model="score" :colors="colors" void-color="#000" text-color="#ff9900" score-template="{value}">
          </el-rate>
        </div>
        <div class="title">*{{ $t('mine.elses.neirong') }}</div>
        <textarea class="textarea" v-model="textarea"></textarea>
        <div class="addbtn" style="width: fit-content" @click="comment">{{ $t('button.tijiao') }}</div>
      </div>
      <div class="comment-list infinite-list-item">
        <div class="h3">{{ isChinese() ? $t('details.pinglun') : 'Reviews' }}（{{ total }}）</div>
        <div v-for="(item, index) of commentList" :key="index" style="margin-bottom: 1.64835vw">
          <div class="title">{{ item.nickName }}</div>
          <div class="title">
            <el-rate v-model="item.score" disabled :colors="colors" void-color="#000" text-color="#ff9900"
              score-template="{value}"> </el-rate>
          </div>
          <div class="info">
            {{ item.content }}
          </div>
          <div class="give dis">
            <span>{{ item.createTime.split(' ')[0] }}</span>
            <span @click="setGive(item, index)">
              <i class="iconfont" :class="item.isLike ? 'icon-dianzan' : 'icon-dianzan1'"></i>
              {{ item.likeNum }}
            </span>
          </div>
          <div style="height: 1.64835vw"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserDetail } from '@/api/main.js'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import $bus from '@/util/bus.js'
import { mapState, mapMutations } from 'vuex'
import { getGoodsDetail, getGoodsSpecPrice, addShoppingCart, collect, comment, listGoodsComment, commentLike } from '@/api/main.js'
export default {
  components: {},
  data() {
    return {
      colors: ['#000', '#000', '#000'],

      number: 1,
      indexTaber: 0,

      isLive: true, //是否收藏
      serperList: [],
      textarea: '',
      toSpeck: true, //去评价
      give: true, //评论点赞
      score: 0, //评论分数

      skuIndex: [],
      mySwiper: null,
      goodsId: '',
      productDetails: {}, //商品详情
      skuList: [], //规格列表
      skuObj: {}, //选中规格
      kety: [], //查询规格的id
      pageNum: 1,
      loadingType: true,
      total: 0,
      commentList: []
    }
  },
  computed: { ...mapState(['userInfo']) },
  mounted() {
    this.goodsId = this.$route.query.id
    this.getUserDetail()
    this.getDetails() //获取商品详情
    this.listGoodsComment() //获取评论

    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.lazyLoading)
  },
  methods: {
    ...mapMutations(['saveUserInfo']),
    //评论点赞
    setGive(item, index) {
      console.log(item)
      commentLike({
        commentId: item.id
      }).then((res) => {
        if (res.code == 0) {
          if (item.isLike == 0) {
            this.commentList[index].likeNum += 1
            this.commentList[index].isLike = 1
          } else {
            this.commentList[index].likeNum -= 1
            this.commentList[index].isLike = 0
          }
        } else {
          this.showMessage()
        }
      })
    },
    //下拉加载
    lazyLoading() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.documentElement.scrollHeight

      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.loadingType) {
          this.loadingType = false
          console.log('到底部了')
          // 滚动到底部，逻辑代码
          this.pageNum++
          this.listGoodsComment()
        }
      }
    },
    comment() {
      if (!localStorage.getItem('token')) {
        // 已登录
        this.$router.push('/login')
        return
      }
      //评论商品
      if (this.score == 0) {
        this.$message({
          message: this.isChinese() ? '请先评分' : 'Please score first',
          type: 'warning'
        })
        return
      }
      if (!this.textarea) {
        this.$message({
          message: this.isChinese() ? '请先输入评论内容' : 'Please enter your comments first',
          type: 'warning'
        })

        return
      }
      comment({
        goodsId: this.goodsId,
        score: this.score,
        content: this.textarea
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.$message({
              message: '评论成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: 'Review success',
              type: 'warning'
            })
          }
          this.toSpeck = true
          this.textarea = ''
          this.pageNum = 1
          this.score = 0
          this.commentList = []
          this.listGoodsComment()
        } else {
          this.showMessage()
        }
      })
    },
    listGoodsComment() {
      //获取评论
      listGoodsComment({
        goodsId: this.goodsId,
        pageNum: this.pageNum,
        pageSize: 10
      }).then((res) => {
        if (res.code == 0) {
          this.loadingType = true
          this.total = res.data.total
          this.commentList.push(...res.data.rows)
        } else {
          this.showMessage()
        }
      })
    },
    //加入购物车
    addShoppingCart() {

      if (!localStorage.getItem('token')) {
        // 已登录
        this.$router.push('/login')
        return
      }
      if (this.skuObj.stock < 1) {
        this.$message({
          message: this.isChinese() ? '库存不足' : 'Understock',
          type: 'success'
        })
        return
      }
      addShoppingCart({
        goodsId: this.goodsId,
        goodsSpecId: this.skuObj.id,
        number: this.number
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.$message({
              message: '加入成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: 'successfully added',
              type: 'success'
            })
          }
          $bus.$emit('updateChat') //更新购物车
        } else {
          this.showMessage()
        }
      })
    },
    //获取商品详情
    getDetails() {
      getGoodsDetail({
        goodsId: this.goodsId
      }).then((res) => {
        if (res.code == 0) {
          this.skuList = res.data.specList //规格
          this.serperList = res.data.goodsDetail.carousel.split(',') //处理轮播图
          console.log('serperList', this.serperList)
          if (this.isChinese()) {
            this.productDetails = res.data.goodsDetail
          } else {
            this.productDetails = {
              ...res.data.goodsDetail,
              name: res.data.goodsDetail.nameEng,
              intro: res.data.goodsDetail.introEng
            }
          }
          this.isLive = this.productDetails.isCollect == 0 ? false : true //是否已收藏

          //插入每位需要第一位
          this.skuList.forEach((item) => {
            this.skuIndex.push(0)
            this.kety.push(item.specParamList[0].id)
          })
          if (this.skuList.length != 0) {
            this.getGoodsSpecPrice()
          }
          let time = setTimeout(() => {
            clearTimeout(time)
            this.initSwiper()
          }, 0)
        } else {
          this.showMessage()
        }
      })
    },
    //选择规格
    selecetSku(index, indexs, item) {
      this.skuIndex[index] = indexs
      this.kety[index] = item.id
      this.getGoodsSpecPrice()
    },
    //查询规格库存和价格
    getGoodsSpecPrice() {
      getGoodsSpecPrice({
        key: this.kety.join(','),
        goodsId: this.goodsId
      }).then((res) => {
        if (res.code == 0) {
          if (this.isChinese()) {
            this.skuObj = res.data
          } else {
            this.skuObj = {
              ...res.data,
              keyName: res.data.keyNameEng
            }
          }
        } else {
          this.showMessage()
        }
      })
    },
    //收藏商品
    collect() {
      if (!localStorage.getItem('token')) {
        // 已登录
        this.$router.push('/login')
        return
      }
      collect({
        goodsId: this.goodsId
      }).then((res) => {
        if (res.code == 0) {
          var title = ''
          if (this.isLive) {
            if (this.isChinese()) {
              title = '已取消'
            } else {
              title = 'Canceled'
            }
          } else {
            if (this.isChinese()) {
              title = '收藏成功'
            } else {
              title = 'Successful collection'
            }
          }
          this.$message({
            message: title,
            type: 'success'
          })
          this.isLive = !this.isLive
        } else {
          this.showMessage()
        }
      })
      // .catch((err) => {
      //   console.error(err)
      //   if (err.msg === '请登录后操作' || err.msgEng === 'Please log in and proceed！') {
      //     // this.$message.error(this.isChinese() ? err.msg : err.msgEng)
      //     localStorage.clear()
      //     setTimeout(() => {
      //       this.$router.replace({
      //         path: '/login'
      //       })
      //     }, 1000)
      //   }
      // })
    },
    initSwiper() {
      this.mySwiper = new Swiper('.swiper-container', {
        //mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 3500, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
          reverseDirection: false //如果最后一个 反向播放
        },
        loop: false, // 循环模式选项
        direction: 'vertical',
        // 如果需要分页器
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true // 分页器可以点击
        // },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      })
      this.mySwiper.on('slideChange', () => {
        this.indexTaber = this.mySwiper.realIndex
      })
    },

    subtract() {
      this.number <= 1 ? '' : this.number--
    },
    add() {
      if (this.number >= this.skuObj.stock) {
        if (this.isChinese()) {
          this.$message({
            message: '库存不足',
            type: 'warning'
          })
        } else {
          this.$message({
            message: 'Insufficient inventory',
            type: 'warning'
          })
        }
        return
      }
      this.number++
    },
    handleChange(value) {
      console.log(value)
    },
    clickTaber(index) {
      this.mySwiper.slideTo(index)
      this.indexTaber = index
    },
    getUserDetail() {
      getUserDetail({}).then((res) => {
        this.saveUserInfo(res.data)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 90px;
}

.swiper-slide {
  display: flex;
  align-items: center;

  img {
    width: 478px;
    // height: 600px
  }
}

.swiper-slide:nth-child(2n) {
  //background-color: #99a9bf;
}

.swiper-slide:nth-child(2n + 1) {
  // background-color: #d3dce6;
}

.dis {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sweper {
  width: 568px;
  max-height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;

  .indicatorss {
    div {
      width: 18px;
      height: 18px;
      background: #e2e2e2;
      border-radius: 50%;
      margin: 20px 0px;
    }

    .active {
      background: #000000;
    }
  }
}

.box {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  padding-top: 90px;
}

.details {
  display: flex;
  flex-direction: column;
  width: 1000px;
  min-height: 600px;
  /* 火狐 */
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;

  .contents {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-shrink: 0;
    height: 600px;
    width: 1000px;

  }

  .title {
    font-size: 56px;
    font-weight: 400;
    color: #333333;
  }

  .skinventory {
    margin: 18px 0px;
    font-size: 17px;

    font-weight: 400;
    color: #777777;
    line-height: 24px;
  }

  .money {
    min-width: 330px;
    max-width: 530px;
    justify-content: flex-start;

    .yyy {
      transform: translateY(-2px);
      margin-right: 20px;

      span {
        margin-right: 12px;
        color: #888;
      }
    }

    div {
      font-size: 32px;

      font-weight: 400;
      color: #c30000;

      span {
        font-size: 32px;

        font-weight: 400;
        color: #333333;
        text-decoration: line-through;
      }
    }

    span {
      font-size: 18px;

      font-weight: 400;
      color: #333333;
    }
  }

  .sku {

    .colors,
    .skus {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 20px;

      div:hover {
        border: 2px solid #000;
        transition: border 0.3s ease;
      }

      .active {
        border: 1px solid #000;
        transition: border 0.3s ease;
      }
    }

    .colors {
      img {
        width: 48px;
        height: 48px;
        background: #b8a9ce;
        margin-right: 20px;
        border: 2px solid #e2e2e2;
        cursor: pointer;
      }
    }

    .skus {
      div {
        margin-right: 20px;
        margin-bottom: 10px;
        padding: 10px 15px;
        border: 1px solid #cccccc;
        font-size: 20px;

        font-weight: 400;
        color: #333333;
        border: 2px solid #e2e2e2;
        cursor: pointer;
      }
    }
  }

  .operator {
    width: 600px;
    margin: 32px 0px;

    .input-number {
      width: 143px;
      // height: 50px;
      border: 1px solid #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      cursor: pointer;

      span {
        font-size: 28px;

        font-weight: 400;
        color: #000000;
      }

      .nuber {
        font-size: 20px;
      }

      .subtract {
        // font-size: 40px;
        // transform: translateY(1px);
      }

      .subtract,
      .add {
        &:hover {
          color: #a1a1a1;
          transition: color 0.3s ease;
        }
      }
    }

    .live {
      &:hover {
        color: #a1a1a1;
        transition: color 0.3s ease;
      }

      font-size: 20px;
      font-family: PingFangSC-Regular,
      PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;

      img {
        vertical-align: middle;
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
    }
  }

  .info {
    font-size: 18px;

    font-weight: 400;
    color: #333333;
  }
}

.addbtn {
  height: 50px;
  margin-right: 20px;
  // width: 210px;
  // height: 50px;
  border: 1px solid transparent;
  border-radius: 0;
  white-space: nowrap;
  transition: all 0.3s ease;
  border: 1px solid #000;
  text-align: center;
  padding: 15px 65px;
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

.addbtn:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}

.comment {
  margin: 80px auto;
  /* 火狐 */
  -moz-user-select: none;
  /* Safari 和 欧朋 */
  -webkit-user-select: none;
  /* IE10+ and Edge */
  -ms-user-select: none;
  /* Standard syntax 标准语法(谷歌) */
  user-select: none;

  .tilte {
    font-size: 24px;

    font-weight: 400;
    color: #333333;
    line-height: 33px;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #ececec;
    text-decoration: underline;
  }

  .nber {
    font-size: 44px;

    font-weight: 400;
    color: #000000;
    line-height: 62px;
    padding-right: 18px;
  }

  .score {
    justify-content: center;
    margin: 35px 0px;

    .el-rate__icon {
      font-size: 35px !important;
    }
  }

  .list {
    font-size: 18px;

    font-weight: 400;
    color: #333333;
    padding-left: 20px;
  }

  .speakBtn {
    justify-content: flex-end;
    padding: 29px 0px;
  }

  .speckInput {
    overflow: hidden;
    height: 450px;
    transition: height 1s ease;

    .h3 {
      font-size: 20px;

      font-weight: 400;
      color: #333333;
      padding: 20px 0px;
      padding-bottom: 0px;
      border-top: 1px solid #ececec;
    }

    .title {
      font-size: 18px;

      font-weight: 400;
      color: #333333;
      line-height: 25px;
      padding: 20px 0px;

      .el-rate__icon {
        font-size: 25px !important;
      }
    }

    .textarea {
      padding: 10px;
      width: 100%;
      resize: vertical;
      height: 100px;
      margin: 20px 0px;
      margin-top: 0px;
      box-sizing: border-box;
    }

    .addbtn {
      width: 170px;
    }
  }

  .speckInpuactive {
    height: 0px;
    transition: height 1s ease;
  }

  .comment-list {
    .h3 {
      font-size: 18px;

      font-weight: 400;
      color: #333333;
      line-height: 25px;
      padding: 12px 0px;
      border-bottom: 1px solid #ececec;
    }

    .title {
      font-size: 18px;

      font-weight: 400;
      color: #333333;
      padding: 15px 0px;
    }

    .info {
      padding: 15px 0px;
      font-size: 20px;

      font-weight: 400;
      color: #333333;
    }

    .give {
      span {
        font-size: 14px;

        font-weight: 400;
        color: #333333;
      }

      i {
        font-size: 20px;
      }
    }
  }
}

.sxxx {
  margin-right: 20px;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  font-size: 20px;

  font-weight: 400;
  color: #333333;
  border: 2px solid #e2e2e2;
  cursor: pointer;
}

.ps_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
