<template>
  <div>
    <!-- <div style="height: 2.8022vw;background-color: #fff;"></div> -->
    <div class="classes2">
      <img class="title__img" :src="titleImg" alt="" />
      <div class="swiper-container" style="height: 38.46154vw" v-if="swiperList.length">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) of swiperList" :key="index">
            <video v-if="item.picture.includes('.mp4')" :src="item.picture" style="width: 100%; height: 100%" controls="controls" autoplay>您的浏览器不支持 video 标签。</video>
            <img v-if="!item.picture.includes('.mp4')" :src="item.picture" style="width: 100%" alt="" mode="widthFix" />
          </div>
        </div>
      </div>
      <div class="pageWapper">
        <!-- <div style="height: 2.74725vw">&nbsp;</div> -->
        <div class="sort">
          <!-- @visible-change="e=> triggerClick" -->
          <el-dropdown trigger="click" @command="triggerClick">
            <span class="el-dropdown-link">
              {{ sort | returSort(isChinese()) }} <i class="el-icon--right" :class="!dropdownActive ? 'el-icon-caret-bottom' : 'el-icon-caret-top'"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="null" icon="el-icon-star-off">{{ isChinese() ? '推荐' : 'Recommend' }}</el-dropdown-item>
              <el-dropdown-item :command="false" icon="el-icon-top">{{ isChinese() ? '升序' : 'Price Low to High' }}</el-dropdown-item>
              <el-dropdown-item :command="true" icon="el-icon-bottom">{{ isChinese() ? '降序' : 'Price High to Low' }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="margin-bottom: 1.0989vw">
          <span class="breadCrumbsData hover" @click="navCollections()">{{ firstBreadCrumbsPathName }}</span>
          <i style="margin-left: 0.27473vw; margin-right: 0.27473vw; display: inline-block">/</i>
          <span class="breadCrumbsData">{{ secondBreadCrumbsPathName }}</span>
        </div>
        <div class="productSku flex_between">
          <div class="strSku" style="width: 17.30769vw; margin-right: 2.74725vw">
            <div class="sku">
              <div class="h2">{{ isChinese() ? '筛选' : 'Filter' }}</div>
              <div v-for="(item, index) of filterList" :key="index">
                <div v-if="item.name == '颜色'">
                  <div class="h3">{{ isChinese() ? item.name : item.nameEng }}</div>
                  <div class="arr">
                    <div class="colors dis" style="margin: 0.54945vw">
                      <div style="padding-right: 0.54945vw; padding-bottom: 0.54945vw" v-for="(items, indexs) of item.specParamList" :key="indexs" @click="selecetSku(index, indexs, items)">
                        <img :class="skuIndex[index] == indexs ? 'active' : ''" v-show="items.picture" :src="items.picture" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="h3">{{ isChinese() ? item.name : item.nameEng }}</div>
                  <div class="arr">
                    <div class="checkbox dis" @click="selecetSku(index, indexs, items)" v-for="(items, indexs) of item.specParamList" :key="indexs">
                      <div :class="skuIndex[index] == indexs ? 'active' : ''"></div>
                      {{ isChinese() ? items.name : items.nameEng }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1">
            <div class="list">
              <!-- initialStyle="productSku" -->
              <productList initialStyle="index" v-for="item of productList" :product="item" :key="item.id"></productList>
              <div v-for="i in hbCount" :key="i + 's'" class="ttttt"></div>
            </div>
            <!-- <div class="pagination">
                            <div class="pages" v-if="total!=0">
                                <div class="dis">
                                    <i class="el-icon-arrow-left" @click="clickLeft" style="font-size: 1.64835vw;"
                                        :style="{ color: pageNum == 1 ? '#999' : '#000' }"></i>
                                    <div>
                                        {{ pageNum }} / {{ Math.ceil(total / 10) }}
                                    </div>
                                    <i class="el-icon-arrow-right" @click="clickRigth" style="font-size: 1.64835vw;"
                                        :style="{ color: pageNum > 1 ? '#000' : '#999' }"></i>
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productList from '@/components/productList.vue'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import $bus from '@/util/bus.js'
import { listCarousel, listGoodsByFilterField, listFilterField, listGoodsClassify } from '@/api/main.js'
export default {
  components: { productList },
  data() {
    return {
      classId: '',
      titleImg: '',
      dropdownActive: false,
      pageNum: 1,
      swiperList: [],
      total: 0,
      goodsClassifyId: '', //分来id
      filterList: [],
      skuIndex: [],
      kety: [],
      productList: [],
      sort: null,
      mySwiper: null,
      breadCrumbsData: {
        first: '',
        second: ''
      },
      firstBreadCrumbsPathName: '',
      secondBreadCrumbsPathName: ''
    }
  },
  computed: {
    hbCount() {
      const x = (this.productList?.length || 0) % 3
      return 3 - x
    }
  },
  mounted() {
    this.goodsClassifyId = this.$route.query.id
    this.classId = this.$route.query.id
    this.getlistCarousel(this.goodsClassifyId) //获取轮播
    this.listFilterField(this.goodsClassifyId) //根据分类查询筛选条件
    this.getlistGoodsClassify(this.goodsClassifyId)
    $bus.$on('productSku', this.updataFilterList)
  },
  filters: {
    returSort(val, isChinese) {
      if (val === null) {
        if (isChinese) {
          return '排序方式：推荐'
        } else {
          return 'Sort By：Recommend'
        }
      }
      if (isChinese) {
        return val ? '排序方式：降序' : '排序方式：升序'
      } else {
        return val ? 'Sort By：Price High to Low' : 'Sort By：Price Low to High'
      }
    }
  },
  methods: {
    navCollections() {
      this.$router.push({ path: '/collections' })
    },
    getlistGoodsClassify(goodsClassifyId) {
      //获取商品分类
      listGoodsClassify({}).then((res) => {
        if (res.code == 0) {
          const resData = res.data || []
          console.log('res.datares.datares.data', res.data)
          let record = {}
          let pid = ''
          resData.map((t) => {
            t.sonList.map((s) => {
              if (s.id === +goodsClassifyId) {
                record.second = s
                console.log(s)
                //this.secondBreadCrumbsPathName = s.name;
                this.secondBreadCrumbsPathName = this.isChinese() ? s.name : s.nameEng
                pid = s.parentId
              }
            })
          })
          resData.map((t) => {
            if (t.id === pid) {
              record.first = t
              console.log(t)
              this.firstBreadCrumbsPathName = this.isChinese() ? t.name : t.nameEng
            }
          })
          this.breadCrumbsData = record
        } else {
          this.showMessage()
        }
      })
    },
    updataFilterList(e) {
      this.classId = e.id
      this.pageNum = 1
      this.productList = []
      this.kety = []
      this.skuIndex = []
      this.filterList = []
      this.initSwiper()
      this.listFilterField(e.id)

      this.getlistGoodsClassify(e.id)
      this.getlistCarousel(e.id)
    },
    listFilterField(goodsClassifyId) {
      //根据分类查询筛选条件
      listFilterField({
        goodsClassifyId
      }).then((res) => {
        if (res.code != 0) this.showMessage(res)
        this.filterList = res.data
        // res.data.forEach((item) => {
        //   // this.skuIndex.push(0)
        //   // this.kety.push(item.specParamList[0].id)
        // })

        this.listGoodsByFilterField()
      })
    },
    listGoodsByFilterField() {
      console.log('this.kety:', this.kety)
      listGoodsByFilterField({
        classId: this.classId,
        key: this.kety.filter((el) => el).join(','),
        pageNum: this.pageNum,
        pageSize: 100,
        sort: this.sort // 降序true，升序false
      }).then((res) => {
        if (res.code != 0) this.showMessage(res)
        if (!res.data) {
          res.data = {
            rows: [],
            total: 0
          }
        }
        this.total = res.data.total
        this.productList.push(...res.data.rows)
      })
    },
    getlistCarousel(goodsClassifyId) {
      //轮播
      listCarousel({ id: goodsClassifyId }).then((res) => {
        console.log('res', res?.data?.[0]?.picture)
        if (res?.data?.[0]?.picture) this.titleImg = res?.data?.[0]?.picture
        // if (res.code != 0) this.showMessage(res)
        // const resData = res.data || []
        // this.swiperList = []
        // if (resData.length) {
        //   for (let i = 0; i < resData.length; i++) {
        //     if (!resData[i]?.picture) continue
        //     if (resData[i]?.picture && resData[i].picture.indexOf('.mp4') > -1) {
        //       this.swiperList = [resData[i]]
        //       return
        //     }
        //     this.swiperList.push(resData[i])
        //   }
        //   let time = setTimeout(() => {
        //     clearTimeout(time)
        //     this.initSwiper()
        //   }, 0)
        // }
      })
    },
    //选择规格
    selecetSku(index, indexs, item) {
      if (this.kety[index] == item.id) {
        this.kety.splice(index, 1)
        this.skuIndex.splice(index, 1)
      } else {
        this.kety[index] = item.id
        this.skuIndex[index] = indexs
      }
      this.pageNum = 1
      this.productList = []
      this.listGoodsByFilterField()
    },
    triggerClick(e) {
      // this.dropdownActive=e
      this.sort = e
      listGoodsByFilterField
      console.log(e)
      this.pageNum = 1
      this.productList = []
      this.listGoodsByFilterField()
    },
    clickLeft() {
      //上一页
      if (this.pageNum <= 1) return
      this.pageNum--
      this.listGoodsByFilterField()
    },
    clickRigth() {
      //下一页
      if (this.pageNum >= Math.ceil(this.total / 10)) return
      this.pageNum++
      this.listGoodsByFilterField()
    },
    initSwiper() {
      this.mySwiper = new Swiper('.swiper-container', {
        direction: 'horizontal', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: {
          //自动开始
          delay: 3500, //时间间隔
          disableOnInteraction: false //*手动操作轮播图后不会暂停*
        },
        loop: false, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 分页器可以点击
        },
        centeredSlides: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      })
      this.mySwiper.on('click', () => {
        if (this.swiperList[this.mySwiper.realIndex].link) {
          window.open(this.swiperList[this.mySwiper.realIndex].link)
        }
      })
    }
  },
  beforeDestroy() {
    console.log('beforeDestr oy???')
    $bus.$off('search')
  }
}
</script>
<style lang="scss" scoped>
.swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.breadCrumbsData {
  font-size: 18px;
  color: #000;
}
.hover {
  cursor: pointer;
}
.pageWapper {
  width: 1700px;
  max-width: 1700px;
  margin: 0 auto;
  padding-top: 20px;
}
.classes2 {
  //padding: 0px 110px;
  background: #f9f9f9;

  .el-dropdown-link {
    font-size: 14px;

    font-weight: 400;
    color: #333333;
    line-height: 30px;
  }
  .sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 0px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: flex-start;
    justify-content: space-between;
  }
  .productSku {
    align-items: flex-start;

    .strSku {
      .sku {
        .h2 {
          margin-top: 40px;
          font-size: 20px;

          font-weight: 400;
          color: #2d2b2c;
          line-height: 28px;
          padding: 10px 0px;
        }
        .h3 {
          font-size: 18px;

          font-weight: 400;
          color: #777777;
          line-height: 30px;
          padding: 20px 0px;
        }
      }
      .arr {
        .checkbox {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          div {
            margin: 10px;
            width: 18px;
            height: 18px;
            border: 1px solid #cccccc;
            margin-right: 10px;
            font-size: 22px;

            font-weight: 400;
            color: #333333;
          }
          &:hover {
            color: #a1a1a1;
            transition: color 0.2s ease 0.2s;
          }
          .active {
            background: #2e2b2c;
          }
        }
        .text {
          font-size: 22px;

          font-weight: 400;
          color: #333333;
          padding: 10px 0px;
          &:hover {
            color: #a1a1a1;
            transition: color 0.2s ease 0.2s;
          }
        }
        .colors {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          img {
            width: 40px;
            height: 40px;
            background: #b8a9ce;
            border: 2px solid #e2e2e2;
            cursor: pointer;
          }
          .active {
            border: 2px solid #000;
            transition: border 0.3s ease;
          }
        }
      }
    }
  }
}
.pagination {
  background-color: #ffffff;
  .pages {
    width: 1200px;
    padding: 40px 0px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    .dis {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;

      font-weight: 400;
      color: #333333;
      width: 200px;
    }
  }
}

.el-dropdown-menu__item {
  line-height: 25px;
}
.title__img {
  width: 100%;
  max-height: 50vh;
  object-fit: contain;
  user-select: none;
  -webkit-user-drag: none;
}
</style>
